import { Component, Input, OnInit } from '@angular/core';
import { DayWeekMonth } from '../../model/day-week-month';
import { TimeRecordingService } from '../../services/time-recording.service';

@Component({
  selector: 'app-day-week-month-chart',
  templateUrl: './day-week-month-chart.component.html',
  styleUrls: ['./day-week-month-chart.component.css'],
})
export class DayWeekMonthChartComponent implements OnInit {
  @Input() showDay: boolean = true;
  @Input() showWeek: boolean = true;
  @Input() showMonth: boolean = true;
  @Input() employeeId: number = 0;

  dayWeekMonth: DayWeekMonth = {
    Day: {
      Percentage: -1,
    },
    Week: {
      Percentage: -1,
    },
    Month: {
      Percentage: -1,
    },
  };

  constructor(private timeRecording: TimeRecordingService) {}

  ngOnInit(): void {
    this.timeRecording.dayWeekMonthStatsQuery.subscribe((dayWeekMonth) => {
      if (dayWeekMonth) {
        // aktualisieren Sie das Diagramm oberflächlich
        this.dayWeekMonth = dayWeekMonth;
      }
    });

    this.timeRecording.getDayWeekMonthStatsQuery(this.employeeId);
  }
}
