import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
  Component,
  NgZone,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { IonContent, ScrollDetail } from '@ionic/angular';
import dayjs from 'dayjs';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { CalendarService } from 'src/app/modules/admin/services/calendar.service';
import { OpenShiftsService } from 'src/app/modules/admin/services/open-shifts.service';
import { AuthService } from 'src/app/modules/auth/services/auth/auth.service';
import { ShiftSwapService } from 'src/app/modules/swap-shift/services/shift-swap.service';
import { TimeRecordingService } from 'src/app/modules/time-recording/services/time-recording.service';
import { BottomNavigationEmployeeService } from 'src/app/navigation/bottom-navigation-employee/bottom-navigation-employee.service';
import { UpdateService } from 'src/app/core/services/pwa/update.service';
import { CompanySettingsService } from 'src/app/modules/settings/services/company-settings.service';

@Component({
  selector: 'app-employee-dashboard',
  templateUrl: './employee-dashboard.component.html',
  styleUrls: ['./employee-dashboard.component.css'],
})
export class EmployeeDashboardComponent implements OnInit, OnDestroy {
  currentTimestamp = new Date().getTime();
  showRefreshText = false;
  showSpinner = false;
  private timerId: any;
  private destroy$ = new Subject<void>();
  private scrollSubscription: Subscription;

  @ViewChild('content', { static: false }) ionContent: IonContent;
  showWelcomeMessage: boolean;
  fadeOut: boolean;
  isMobile: boolean;

  constructor(
    private calendarService: CalendarService,
    private shiftSwapService: ShiftSwapService,
    private openShiftService: OpenShiftsService,
    public authService: AuthService,
    public bottomNavigationEmployeeService: BottomNavigationEmployeeService,
    private ngZone: NgZone,
    private breakpointObserver: BreakpointObserver,
    private renderer: Renderer2,
    private timeRecordingService: TimeRecordingService,
    private updateService: UpdateService,
    public companySettingsService: CompanySettingsService
  ) {}

  ngOnInit(): void {
    const body = document.querySelector('body');
    if (body) {
      this.renderer.setStyle(body, 'overflow-y', 'hidden');
    }
    this.timerId = setInterval(() => {
      const now = new Date().getTime();
      const diff = now - this.currentTimestamp;
      // Zeige Refresh-Button, wenn 2 Stunden seit dem letzten Refresh vergangen sind
      this.showRefreshText = diff > 2 * 60 * 60 * 1000;
      // this.showRefreshText = diff > 8000; // Für Testzwecke
    }, 60000); // Überprüfe jede Minute

    this.checkWelcomeMessage();

    // Beispiel-Abonnement für Scroll-Events mit debounceTime
    this.scrollSubscription = this.bottomNavigationEmployeeService.scrollEvent$
      .pipe(takeUntil(this.destroy$), debounceTime(100)) // Passe die debounceTime nach Bedarf an
      .subscribe((scrollY) => {
        this.ngZone.runOutsideAngular(() => {
          this.handleScroll({
            detail: { scrollTop: scrollY },
          } as CustomEvent<ScrollDetail>);
        });
      });

    this.breakpointObserver
      .observe([Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge])
      .subscribe((result) => {
        this.isMobile = !result.matches;
      });
  }

  ngOnDestroy(): void {
    clearInterval(this.timerId);
    this.destroy$.next();
    this.destroy$.complete();
    if (this.scrollSubscription) {
      this.scrollSubscription.unsubscribe();
    }
  }

  ngAfterViewInit() {
    this.bottomNavigationEmployeeService.setScrollableContentElement(
      this.ionContent
    );
  }

  handleRefresh(event) {
    this.showSpinner = true;
    this.currentTimestamp = new Date().getTime();
    this.showRefreshText = false;

    // Aufruf der performRefresh Methode des UpdateService
    this.updateService.performRefresh().subscribe({
      next: (results) => {
        console.log('Aktualisierungsergebnisse:', results);
        // Optional: Weitere Verarbeitung der Ergebnisse

        setTimeout(() => {
          // Beende den Refresh-Zustand
          event.target.complete();
          this.showSpinner = false;
        }, 1500);
      },
      error: (err) => {
        console.error('Fehler beim Aktualisieren:', err);
        // Optional: Zeige eine Fehlermeldung an den Benutzer

        setTimeout(() => {
          // Beende den Refresh-Zustand auch bei Fehlern
          event.target.complete();
          this.showSpinner = false;
        }, 1500);
      },
    });
  }

  checkWelcomeMessage(): void {
    const lastLoginTime = localStorage.getItem('lastLoginTime');
    const welcomeMessageShown = localStorage.getItem('welcomeMessageShown');

    if (!lastLoginTime || !welcomeMessageShown) {
      this.showWelcomeMessage = true;
      localStorage.setItem('welcomeMessageShown', 'true');
      this.authService.setLoginTimestamp();
    } else {
      const lastLoginDate = new Date(lastLoginTime);
      const currentDate = new Date();
      const hoursDifference =
        Math.abs(currentDate.getTime() - lastLoginDate.getTime()) / 36e5;

      if (hoursDifference > 24) {
        this.showWelcomeMessage = true;
        localStorage.setItem('welcomeMessageShown', 'true');
        this.authService.setLoginTimestamp();
      }
    }

    if (this.showWelcomeMessage) {
      setTimeout(() => {
        this.fadeOut = true;
      }, 5000); // Starte die Animation nach 5 Sekunden
      setTimeout(() => {
        this.showWelcomeMessage = false;
        this.fadeOut = false; // Setze die Klasse für das nächste Mal zurück
      }, 10000); // Entferne die Nachricht nach 10 Sekunden (5 Sekunden sichtbare Nachricht + 5 Sekunden Animation)
    }
  }

  handleScroll(ev: CustomEvent<ScrollDetail>) {
    this.ngZone.run(() => {
      this.bottomNavigationEmployeeService.emitScrollEvent(ev.detail.scrollTop);
    });
  }
}
