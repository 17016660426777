import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { SavePassword } from 'capacitor-ios-autofill-save-password';
import { AuthService } from '../../services/auth/auth.service';
import { OnboardingProcessService } from '../../services/onboarding-process.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  // Zeigt an, ob wir als Terminal einloggen oder als normaler Mitarbeiter
  isTerminalMode = false;

  formModel = {
    EmailOrUserName: '', // wir verwenden hier denselben Key
    Password: '',
  };

  errorStatus: any;

  /** NEU: Schalter, um den .local-Hinweis anzuzeigen */
  showLocalEmailHint = false;

  constructor(
    private route: ActivatedRoute,
    private service: AuthService,
    private router: Router,
    private onboardingService: OnboardingProcessService
  ) {
    if (
      this.route.snapshot.queryParams &&
      this.route.snapshot.queryParams.param
    ) {
      localStorage.setItem('token', this.route.snapshot.queryParams.param);
    }
    this.errorStatus = this.service.error;
  }

  async ngOnInit(): Promise<void> {
    if (localStorage.getItem('token') != null) {
      // Wenn schon eingeloggt -> Weiterleitung
      if (this.service.isManegeOrTeamLeader) {
        const onboardingCompleted =
          await this.onboardingService.isOnboardingCompleted();
        if (!onboardingCompleted) {
          this.router.navigateByUrl('/registered-stepper');
        } else {
          this.router.navigateByUrl('/dashboard');
        }
      } else if (this.service.isTerminal) {
        this.router.navigateByUrl('/terminal-dashboard');
      } else {
        this.router.navigateByUrl('/employeedashboard');
      }
    } else if (this.service.Email != null) {
      // Autovervollständigung
      this.formModel.EmailOrUserName = this.service.Email;
      this.formModel.Password = '';
    }
  }

  async onSubmit(form: NgForm): Promise<void> {
    // Whitespaces entfernen
    this.formModel.EmailOrUserName =
      this.formModel.EmailOrUserName.trim().replace(/\s+/g, '');

    // NEU: Check, ob .local => setze showLocalEmailHint
    this.checkLocalEmailHint();

    if (this.isTerminalMode) {
      // Terminal-Login
      this.service.loginTerminal({
        UserName: this.formModel.EmailOrUserName,
        Password: this.formModel.Password,
      });
    } else {
      // Normaler E-Mail-Login
      this.service.login({
        Email: this.formModel.EmailOrUserName,
        Password: this.formModel.Password,
      });
    }

    // iOS-Passwortmanager
    if (Capacitor.getPlatform() === 'ios') {
      await SavePassword.promptDialog({
        username: this.formModel.EmailOrUserName,
        password: this.formModel.Password,
      });
    }
  }

  loginExternal(): void {
    this.service.loginExternal();
  }

  /** NEU: Toggle => Felder leeren, Hinweis verbergen */
  toggleLoginMode(): void {
    this.isTerminalMode = !this.isTerminalMode;
    this.formModel.EmailOrUserName = '';
    this.formModel.Password = '';
    this.errorStatus = { showError: false, errorMessage: '' };
    this.showLocalEmailHint = false; // Reset, da wir Modus wechseln
  }

  /** NEU: Check, ob E-Mail/Username auf .local endet und normaler Mode */
  private checkLocalEmailHint(): void {
    if (
      !this.isTerminalMode &&
      this.formModel.EmailOrUserName.toLowerCase().endsWith('.local')
    ) {
      this.showLocalEmailHint = true;
    } else {
      this.showLocalEmailHint = false;
    }
  }
}
