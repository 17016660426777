import {
  Component,
  OnInit,
  OnDestroy,
  NgZone,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { Subscription, timer } from 'rxjs';
import dayjs from 'dayjs';
import {
  TerminalService,
  TerminalEmployeeDto,
} from '../../services/terminal.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PinPadDialogComponent } from '../pin-pad-dialog/pin-pad-dialog.component';
import { TimeDialogComponent } from 'src/app/modules/time-recording/component/time-dialog/time-dialog.component';
import { TranslateService } from '@ngx-translate/core';

/** NEU: Für Inaktivität */
import { fromEvent, merge } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { CompanySettingsService } from 'src/app/modules/settings/services/company-settings.service';

@Component({
  selector: 'app-terminal-dashboard',
  templateUrl: './terminal-dashboard.component.html',
  styleUrls: ['./terminal-dashboard.component.css'],
})
export class TerminalDashboardComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild('employeeList') employeeListRef: ElementRef;
  currentTime: string;
  currentDate: string;

  searchTerm: string = '';
  pageIndex = 1;
  pageSize = 10; // Standard
  totalCount = 0;

  employees: TerminalEmployeeDto[] = [];

  private timerSub: Subscription;

  /** INAKTIVITÄT: Neuer Timer + Subscription */
  private inactivityTimer: any;
  private globalEventsSub: Subscription;

  constructor(
    private terminalService: TerminalService,
    private dialog: MatDialog,
    private zone: NgZone,
    private translate: TranslateService,
    /** NEU: um Timeout-Wert aus Settings zu holen */
    public companySettingsService: CompanySettingsService
  ) {}

  ngOnInit(): void {
    // Globalen Scroll unterbinden nur in dieser Komponente
    document.body.style.overflow = 'hidden';
    document.documentElement.style.overflow = 'hidden';

    this.timerSub = timer(0, 1000).subscribe(() => {
      const now = dayjs();
      this.currentTime = now.format('HH:mm');
      this.currentDate = now.format('dddd, D. MMMM');
    });

    /** NEU: Inaktivitäts-Mechanismus starten */
    this.initInactivityTimer();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.adjustPageSizeForContainer();
      this.loadEmployees(true);
    }, 0);
  }

  ngOnDestroy(): void {
    // Scroll wieder erlauben:
    document.body.style.overflow = '';
    document.documentElement.style.overflow = '';

    if (this.timerSub) {
      this.timerSub.unsubscribe();
    }

    /** NEU: Aufräumen Inaktivitäts-Timer */
    if (this.inactivityTimer) {
      clearTimeout(this.inactivityTimer);
    }
    if (this.globalEventsSub) {
      this.globalEventsSub.unsubscribe();
    }
  }

  /** Dynamisch PageSize je nach Höhe der employee-list. */
  private adjustPageSizeForContainer(): void {
    if (!this.employeeListRef) return;
    const containerHeight = this.employeeListRef.nativeElement.clientHeight;
    const itemHeight = 80;
    let itemsThatFit = Math.floor(containerHeight / itemHeight);
    if (itemsThatFit < 5) {
      itemsThatFit = 5;
    }
    itemsThatFit += 2;
    this.pageSize = itemsThatFit;
  }

  loadEmployees(reset: boolean): void {
    if (reset) {
      this.pageIndex = 1;
      this.employees = [];
    }
    this.terminalService
      .getTerminalEmployeeList(
        this.searchTerm,
        true,
        this.pageIndex,
        this.pageSize
      )
      .subscribe((result) => {
        this.totalCount = result.Pagination.AllItemsLength;
        this.employees = [...this.employees, ...result.DataList];
      });
  }

  onSearchChange(): void {
    this.pageIndex = 1;
    this.employees = [];
    this.loadEmployees(true);
  }

  onScrollDown(): void {
    if (this.employees.length < this.totalCount) {
      this.pageIndex++;
      this.loadEmployees(false);
    }
  }

  /**
   * Button-Klick statt Toggle.
   * Öffnet den PIN-Dialog, validiert. Falls OK => Zeitdialog ...
   */
  onClockAction(emp: TerminalEmployeeDto): void {
    if (
      this.companySettingsService.CompanySettings.TerminalSettings?.AllowPin
    ) {
      const dialogRef = this.dialog.open(PinPadDialogComponent, {
        width: '300px',
      });

      dialogRef.afterClosed().subscribe((pinValue: string | null) => {
        if (pinValue) {
          this.terminalService
            .validatePinAndClockEvent(emp.EmployeeId, pinValue)
            .subscribe({
              next: (res: any) => {
                if (res?.Success) {
                  this.openTimeDialog(emp.EmployeeId);
                  this.pageIndex = 1;
                  this.employees = [];
                  this.loadEmployees(true);
                }
              },
              error: (err) => {
                console.error(err);
              },
            });
        }
      });
    } else {
      // Kein PIN => Direkt öffnen
      this.openTimeDialog(emp.EmployeeId);
    }
  }

  /**
   * Je nach showStatus und emp.Status
   *   => "ClockedIn" vs. "ClockedOut"
   *   => oder "Einstempeln" / "Ausstempeln"
   */
  getClockButtonLabel(emp: TerminalEmployeeDto): string {
    if (
      this.companySettingsService.CompanySettings.TerminalSettings
        .ShowEmployeeStatus
    ) {
      // Zeige den tatsächlichen Status als Übersetzung
      switch (emp.Status) {
        case 'ClockedIn':
          return this.translate.instant('STATUS_CLOCKED_IN');
        case 'ClockedOut':
          return this.translate.instant('STATUS_CLOCKED_OUT');
        case 'OnBreak':
          return this.translate.instant('STATUS_ON_BREAK');
        case 'NoRecord':
          return this.translate.instant('STATUS_NO_RECORD');
        default:
          return this.translate.instant('STATUS_UNKNOWN');
      }
    } else {
      // showStatus == false => neutraler Text
      return this.translate.instant('CLOCK_ACTION_NEUTRAL');
    }
  }

  openTimeDialog(employeeId): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.maxHeight = '90vh';
    dialogConfig.maxWidth = '95vw';

    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.data = { employeeId };
    this.zone.run(() => {
      this.dialog.open(TimeDialogComponent, dialogConfig);
    });
  }

  onQrScan(): void {
    alert('QR-Scan not implemented.');
  }

  // --------------------[Inaktivitäts-Logik ab hier]-------------------- //
  private initInactivityTimer(): void {
    // Hole den Timeout-Wert z.B. aus CompanySettings.TerminalSettings
    // Fallback 15 Sek, wenn nichts eingestellt
    const inactivitySecs =
      this.companySettingsService.CompanySettings.TerminalSettings
        ?.PinPadInactivityTimeout ?? 15;
    const inactivityMillis = inactivitySecs * 1000;

    this.resetInactivityTimer(inactivityMillis);

    // Globale Events => Timer reset
    const mousemove$ = fromEvent(document, 'mousemove');
    const click$ = fromEvent(document, 'click');
    const keydown$ = fromEvent(document, 'keydown');
    const touchstart$ = fromEvent(document, 'touchstart');

    this.globalEventsSub = merge(mousemove$, click$, keydown$, touchstart$)
      .pipe(debounceTime(300))
      .subscribe(() => {
        this.resetInactivityTimer(inactivityMillis);
      });
  }

  private resetInactivityTimer(inactivityMillis: number): void {
    if (this.inactivityTimer) {
      clearTimeout(this.inactivityTimer);
    }
    this.inactivityTimer = setTimeout(() => {
      // Wenn Suchbegriff noch gefüllt, leeren wir ihn und laden neu
      if (this.searchTerm) {
        this.searchTerm = '';
        this.onSearchChange();
      }
    }, inactivityMillis);
  }
}
