import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { CompanySettings } from '../../model/company-settings.model';
import { CompanySettingsService } from '../../services/company-settings.service';

@Component({
  selector: 'app-terminal-settings',
  templateUrl: './terminal-settings.component.html',
  styleUrls: ['./terminal-settings.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class TerminalSettingsComponent implements OnInit {
  companySettings: CompanySettings = new CompanySettings();

  constructor(
    private companySetting: CompanySettingsService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.companySetting.CompanySettingsObservable.subscribe((settings) => {
      if (settings) {
        this.companySettings = settings;
      }
    });
    this.companySetting.getCompanySettings();
  }

  onSubmit(form: NgForm): void {
    // Evtl. eigenes Save-Handling, falls nötig
  }

  onUpdate(): void {
    this.companySetting.updateCompanySettings(this.companySettings);
  }
}
