<div class="period-selection">
  <div
    class="period-button"
    [class.selected]="period === BalancePeriod.Week"
    (click)="onPeriodSelect(BalancePeriod.Week)"
  >
    {{ "Week" | translate }}
  </div>
  <div
    class="period-button"
    [class.selected]="period === BalancePeriod.Month"
    (click)="onPeriodSelect(BalancePeriod.Month)"
  >
    {{ "Month" | translate }}
  </div>
  <div
    class="period-button"
    [class.selected]="period === BalancePeriod.Year"
    (click)="onPeriodSelect(BalancePeriod.Year)"
  >
    {{ "Year" | translate }}
  </div>
  <div class="expander" (click)="toggleExpand()">
    <i
      class="fa-light"
      [ngClass]="isExpanded ? 'fa-angles-down' : 'fa-angles-right'"
    ></i>
  </div>
</div>

<div class="employee-hour-balance-card">
  <div class="stats-grid" [ngClass]="{ collapsed: !isExpanded }">
    <div class="stat-item">
      <i class="icon-style"></i>
      <span class="label">{{ "Overall Hour Balance" | translate }}:</span>
      <span class="value">{{ hourBalance?.OverallHourBalance }}</span>
    </div>
    <div class="stat-item">
      <i class="icon-style"></i>
      <span class="label">{{ "Hour Balance" | translate }}:</span>
      <span class="value">{{ hourBalance?.HourBalance }}</span>
    </div>
    <div class="stat-item">
      <i class="icon-style"></i>
      <span class="label">{{ "Hours Due" | translate }}:</span>
      <span class="value">{{ hourBalance?.HoursDue }}</span>
    </div>
    <div class="stat-item">
      <i class="icon-style"></i>
      <span class="label">{{ "Overtime" | translate }}:</span>
      <span class="value">{{ hourBalance?.Overtime }}</span>
    </div>
    <div class="stat-item">
      <i class="icon-style"></i>
      <span class="label">{{ "Hours Worked" | translate }}:</span>
      <span class="value">{{ hourBalance?.HoursWorked }}</span>
    </div>
    <div class="stat-item">
      <i class="icon-style"></i>
      <span class="label">{{ "Working Hours" | translate }}:</span>
      <span class="value">{{ hourBalance?.WorkingHours }}</span>
    </div>
    <div class="stat-item">
      <i class="icon-style"></i>
      <span class="label">{{ "Total Overtime Overall" | translate }}:</span>
      <span class="value">{{ hourBalance?.TotalOvertimeOverall }}</span>
    </div>
  </div>
</div>
