<div class="container-fluid header-ion-safe">
  <ngx-spinner type="ball-clip-rotate-multiple" color="#12deec"></ngx-spinner>
  <app-page-header *ngIf="!isMobile && isLoggedin"></app-page-header>
  <app-page-header-terminal
    *ngIf="authService.isTerminal && isLoggedin"
  ></app-page-header-terminal>
  <app-mobile-page-header
    *ngIf="
      isLoggedin &&
      isMobile &&
      authService.isManegeOrTeamLeader &&
      !authService.isTerminal
    "
  ></app-mobile-page-header>
  <app-bottom-navigation-employee
    *ngIf="
      isLoggedin &&
      isMobile &&
      !authService.isManegeOrTeamLeader &&
      !authService.isTerminal
    "
  ></app-bottom-navigation-employee>
  <router-outlet></router-outlet>
  <app-footer></app-footer>
</div>
