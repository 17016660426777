import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/modules/auth/services/auth/auth.service';
import { LoaderService } from '../../core/services/loader/loader.service';

@Component({
  selector: 'app-page-header-terminal',
  templateUrl: './page-header-terminal.component.html',
  styleUrls: ['./page-header-terminal.component.css'],
})
export class PageHeaderTerminalComponent implements OnInit {
  currentRoute: string;
  isRegisteredStepper: boolean = false;
  constructor(
    public authService: AuthService,
    public loaderService: LoaderService,
    private changeDetectorRef: ChangeDetectorRef,
    private translate: TranslateService
  ) {}
  public loading = false;
  public isMenuCollapsed = true;
  openShiftNumber = 0;
  lang;
  ngOnInit(): void {
    this.changeDetectorRef.detach();
    this.loaderService.isLoading.subscribe((res) => {
      this.loading = res > 0;
      this.changeDetectorRef.detectChanges();
    });

    this.lang = this.translate.currentLang;
  }

  changeLocale(lang: string): void {
    localStorage.setItem('lang', lang);
    window.location.reload();
    this.isMenuCollapsed = true;
  }

  onLogoutClick(): void {
    this.authService.goToLogin();
    this.isMenuCollapsed = true;
  }
}
