<div class="pin-dialog-container">
  <h2 class="dialog-title">
    {{ "TITLE" | translate }}
  </h2>

  <!-- Anzeige des aktuellen PIN-Eingabestands -->
  <div class="pin-display">
    <span *ngFor="let digit of pinArray; let i = index" class="digit">
      {{ digit }}
    </span>
  </div>

  <!-- PIN-Pad Buttons -->
  <div class="pin-pad-grid">
    <!-- Zahlen 1-9 -->
    <button
      class="pin-button"
      *ngFor="let num of ['1', '2', '3', '4', '5', '6', '7', '8', '9']"
      (click)="onNumberClick(num)"
    >
      {{ num }}
    </button>

    <!-- Sonderbuttons: Clear / 0 / Delete -->
    <button class="pin-button special" (click)="onClear()">
      {{ "CLEAR" | translate }}
    </button>
    <button class="pin-button" (click)="onNumberClick('0')">0</button>
    <button class="pin-button special" (click)="onDelete()">
      <mat-icon>backspace</mat-icon>
    </button>
  </div>

  <!-- Aktionen -->
  <div class="dialog-actions">
    <button class="btn btn-outline-secondary" (click)="onCancel()">
      {{ "CANCEL" | translate }}
    </button>
    <button class="btn btn-primary" (click)="onConfirm()">
      {{ "OK" | translate }}
    </button>
  </div>
</div>
