<div *ngIf="terminalList?.length === 0" class="alert alert-info" role="alert">
  {{ "NO_TERMINALS_FOUND" | translate }}
</div>
<table class="table" *ngIf="terminalList?.length > 0">
  <thead></thead>
  <tbody>
    <tr *ngFor="let terminal of terminalList">
      <td>{{ terminal.UserName }}</td>
      <td>{{ terminal.Email }}</td>
      <td style="text-align: center">
        <button mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button
            mat-menu-item
            (click)="onDeleteTerminal(terminal.UserName, terminal.Email)"
          >
            <mat-icon>delete</mat-icon>
            <span>{{ "DELETE" | translate }}</span>
          </button>
        </mat-menu>
      </td>
    </tr>
  </tbody>
</table>
