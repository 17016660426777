import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import {
  CompanySettings,
  WorkStructure,
} from '../../model/company-settings.model';
import { CompanySettingsService } from '../../services/company-settings.service';
import {
  Country,
  LocationService,
  Region,
  TimeZone,
} from '../../services/location.service';
import { HolidayService } from 'src/app/modules/holiday/services/holiday.service';

@Component({
  selector: 'app-company-settings',
  templateUrl: './company-settings.component.html',
  styleUrls: ['./company-settings.component.css'],
})
export class CompanySettingsComponent implements OnInit {
  companySettings: CompanySettings = new CompanySettings();
  WorkStructure = WorkStructure;

  // generate a list of days of the week for the dropdown list key is the number day of the week
  daysOfWeek = [
    { key: 0, value: this.translate.instant('SUNDAY') },
    { key: 1, value: this.translate.instant('MONDAY') },
    { key: 2, value: this.translate.instant('TUESDAY') },
    { key: 3, value: this.translate.instant('WEDNESDAY') },
    { key: 4, value: this.translate.instant('THURSDAY') },
    { key: 5, value: this.translate.instant('FRIDAY') },
    { key: 6, value: this.translate.instant('SATURDAY') },
  ];

  companyWorkStructure = [
    {
      key: WorkStructure.Departments,
      value: this.translate.instant('DEPARTMENTS'),
    },
    { key: WorkStructure.Projects, value: this.translate.instant('PROJECTS') },
    { key: WorkStructure.Both, value: this.translate.instant('HYBRID') },
  ];

  // Liste der Zeitzonen für die Dropdown-Auswahl
  timeZones: TimeZone[] = [];
  countries: any[] = [{ key: 0, value: 'Select Country' }];
  regions: any[] = [{ key: 0, value: 'Select Region' }];
  isoCountryCode: string = null;
  selectedTime: string = '';
  suggestions = ['10', '15', '20', '25', '30', '40', '45', '50', '60'];

  constructor(
    private companySetting: CompanySettingsService,
    private translate: TranslateService,
    private locationService: LocationService,
    private holidayService: HolidayService
  ) {}

  ngOnInit(): void {
    this.companySetting.CompanySettingsObservable.subscribe((settings) => {
      if (settings) {
        this.companySettings = settings;
        this.holidayService.getCompanyHolidays(new Date().getFullYear());
      }
    });
    this.companySetting.getCompanySettings();

    this.locationService.getAllCountries();
    this.locationService.countries$.subscribe((countries: Country[]) => {
      // map the countries to the dropdown list
      this.countries = countries.map((country) => {
        return { key: country.IsoCountryCode, value: country.CountryName };
      });
    });
    this.onCountryChange();
    this.loadTimeZones();
  }

  loadTimeZones() {
    this.locationService.timeZones$.subscribe((timeZones) => {
      this.timeZones = timeZones;
    });
    this.locationService.getTimeZones();
  }

  onSubmit(form: NgForm): void {}

  onUpdate(value): void {
    this.companySetting.updateCompanySettings(this.companySettings);
    if (
      this.companySetting.CompanySettings.IsoCountryCode !== this.isoCountryCode
    ) {
      this.onCountryChange();
    }
  }

  onCountryChange(): void {
    this.isoCountryCode = this.companySetting.CompanySettings.IsoCountryCode;
    this.locationService.getRegionsByCountry(
      this.companySetting.CompanySettings.IsoCountryCode
    );
    this.locationService.regions$.subscribe((regions) => {
      // map the regions to the dropdown list
      this.regions = regions.map((region) => {
        return { key: region.IsoRegionCode, value: region.RegionName };
      });
    });
  }
}
