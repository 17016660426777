import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SortShiftBy } from 'src/app/core/services/const';
import { AbsenceService } from 'src/app/modules/absence/services/absence.service';
import { AuthService } from 'src/app/modules/auth/services/auth/auth.service';
import { EmployeeService } from 'src/app/modules/employee/services/employee.service';
import { CalendarService } from '../../services/calendar.service';
import { OpenShiftsService } from '../../services/open-shifts.service';
import { CompanySettingsService } from 'src/app/modules/settings/services/company-settings.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {
  currentTimestamp = new Date().getTime();
  showRefreshText = false;
  private timerId: any;
  employeeCount = 0;
  alertMessage: string = this.translate.instant(
    'PLEASE ADD A DEPARTMENT OR QUALIFICATION UNDER COMPANY IN THE SELECTION ABOVE'
  );
  showWelcomeMessage: boolean;
  fadeOut: boolean;

  constructor(
    public authService: AuthService,
    private calenderServie: CalendarService,

    private openShiftService: OpenShiftsService,
    private absencesService: AbsenceService,
    private employeeService: EmployeeService,
    private translate: TranslateService,
    public companySettingsService: CompanySettingsService
  ) {}
  ngOnInit(): void {
    this.timerId = setInterval(() => {
      const now = new Date().getTime();
      const diff = now - this.currentTimestamp;
      // show refresh button if 2 hours have passed since last refresh
      this.showRefreshText = diff > 2 * 60 * 60 * 1000;
    }, 60000); // check every second

    this.employeeService.employeeCount.subscribe((count) => {
      this.employeeCount = count;
    });
    this.employeeService.getEmployeeCount();

    this.checkWelcomeMessage();
  }

  ngOnDestroy(): void {
    clearInterval(this.timerId);
  }

  handleRefresh(event) {
    this.currentTimestamp = new Date().getTime();
    this.showRefreshText = false;
    this.openShiftService.getOpenShiftListForAdmin();
    this.absencesService.GetAbsenceRequests();
    this.calenderServie.currentFilter = SortShiftBy.ByShiftStart;
    this.calenderServie.getTimelineEvent(
      true,
      Date.now(),
      true,
      false,
      true,
      '*'
    );

    setTimeout(() => {
      // Any calls to load data go here
      event.target.complete();
      this.showRefreshText = false;
    }, 1500);
  }

  checkWelcomeMessage(): void {
    const lastLoginTime = localStorage.getItem('lastLoginTime');
    const welcomeMessageShown = localStorage.getItem('welcomeMessageShown');

    if (!lastLoginTime || !welcomeMessageShown) {
      this.showWelcomeMessage = true;
      localStorage.setItem('welcomeMessageShown', 'true');
      this.authService.setLoginTimestamp();
    } else {
      const lastLoginDate = new Date(lastLoginTime);
      const currentDate = new Date();
      const hoursDifference =
        Math.abs(currentDate.getTime() - lastLoginDate.getTime()) / 36e5;

      if (hoursDifference > 24) {
        this.showWelcomeMessage = true;
        localStorage.setItem('welcomeMessageShown', 'true');
        this.authService.setLoginTimestamp();
      }
    }

    if (this.showWelcomeMessage) {
      setTimeout(() => {
        this.fadeOut = true;
      }, 5000); // Start der Animation nach 5 Sekunden
      setTimeout(() => {
        this.showWelcomeMessage = false;
        this.fadeOut = false; // Zurücksetzen der Klasse für das nächste Mal
      }, 10000); // Entfernen der Nachricht nach 10 Sekunden (5 Sekunden sichtbare Nachricht + 5 Sekunden Animation)
    }
  }
}
