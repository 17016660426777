export class EmployeeWorkHoursDto {
  Id: number;
  EmployeeName: string;
  Department: string;
  DepartmentId: number;

  // Bisher:
  TotalWorkMinutes: number;
  TotalOvertimeMinutes: number;

  // NEU:
  TargetWorkMinutes: number; // "Stunden Soll"
  EffectiveMinutes: number; // "Stunden Ist"
  PureWorkMinutes: number; // "Arbeitsstunden"
  AbsenceDays: number; // "Abwesenheitstage"

  // ...
  totalWorkTime: string; // formatiertes Feld (alte Logik)
  totalOvertimeTime: string;
  targetWorkTime: string; // NEU (Format hh:mm?)
  effectiveWorkTime: string;
  pureWorkTime: string;

  TimeLine: TimeLineDto;
}

export class TimeLineDto {
  WorkTime: number[]; // time in minutes for each day
  Overtime: number[]; // over time in minutes for each day
  Days: Date[]; // days of the week
}
