<div class="table-container">
  <mat-table
    [dataSource]="listData"
    matSort
    (matSortChange)="sortEmployeeData($event)"
  >
    <!-- Spalte: EmployeeName -->
    <ng-container matColumnDef="EmployeeName">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        style="font-size: 16px; color: var(--font-color); padding: 0 12px"
      >
        {{ "EMPLOYEE NAME" | translate }}
      </mat-header-cell>
      <mat-cell
        *matCellDef="let element"
        style="
          color: var(--primary-color-blue);
          font-weight: bold;
          padding: 0 12px;
        "
      >
        <h6 style="margin: 0; display: flex">
          <i
            class="fa-light fa-circle-user fg-lg"
            style="font-size: x-large; margin-right: 6px"
          ></i>
          {{ element.EmployeeName }}
        </h6>
      </mat-cell>
    </ng-container>

    <!-- Spalte: Department -->
    <ng-container matColumnDef="Department">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        style="
          font-size: 16px;
          color: var(--font-color);
          text-align: center;
          padding: 0 12px;
        "
      >
        {{ "Department" | translate }}
      </mat-header-cell>
      <mat-cell
        *matCellDef="let element"
        style="text-align: center; padding: 0 12px"
      >
        {{ element.Department }}
      </mat-cell>
    </ng-container>

    <!-- Spalte: Diagram (Bar-Chart) -->
    <ng-container matColumnDef="Diagram">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        style="
          font-size: 16px;
          color: var(--font-color);
          text-align: center;
          padding: 0 12px;
        "
      >
        {{ "Diagram" | translate }}
      </mat-header-cell>
      <mat-cell
        *matCellDef="let element"
        style="text-align: center; padding: 0 12px; width: 210px"
      >
        <div style="margin: 0 auto; width: 210px; height: 50px">
          <app-employee-time-bar-chart
            [chartId]="element.Id + '-' + element.DepartmentId"
            [chartData]="element.TimeLine"
          ></app-employee-time-bar-chart>
        </div>
      </mat-cell>
    </ng-container>

    <!-- Spalte: SOLL (targetWorkTime) -->
    <ng-container matColumnDef="TargetWorkTime">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        style="
          font-size: 16px;
          color: var(--font-color);
          text-align: center;
          padding: 0 12px;
        "
      >
        {{ "SHOULD" | translate }}
      </mat-header-cell>
      <mat-cell
        *matCellDef="let element"
        style="text-align: center; padding: 0 12px"
      >
        {{ element.targetWorkTime }}
      </mat-cell>
    </ng-container>

    <!-- Spalte: IST (effectiveWorkTime) -->
    <ng-container matColumnDef="EffectiveWorkTime">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        style="
          font-size: 16px;
          color: var(--font-color);
          text-align: center;
          padding: 0 12px;
        "
      >
        {{ "IS" | translate }}
      </mat-header-cell>
      <mat-cell
        *matCellDef="let element"
        style="text-align: center; padding: 0 12px"
      >
        {{ element.effectiveWorkTime }}
      </mat-cell>
    </ng-container>

    <!-- Spalte: ARBEITSSTUNDEN (pureWorkTime) -->
    <ng-container matColumnDef="PureWorkTime">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        style="
          font-size: 16px;
          color: var(--font-color);
          text-align: center;
          padding: 0 12px;
        "
      >
        ARBEITSSTUNDEN
      </mat-header-cell>
      <mat-cell
        *matCellDef="let element"
        style="text-align: center; padding: 0 12px"
      >
        {{ element.pureWorkTime }}
      </mat-cell>
    </ng-container>

    <!-- Spalte: TotalWorkTime (WORKING HOURS) -->
    <ng-container matColumnDef="TotalWorkTime">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        style="
          font-size: 16px;
          color: var(--font-color);
          text-align: center;
          padding: 0 12px;
        "
      >
        {{ "WORKING HOURS" | translate }}
      </mat-header-cell>
      <mat-cell
        *matCellDef="let element"
        style="text-align: center; padding: 0 12px"
      >
        {{ element.totalWorkTime }}
      </mat-cell>
    </ng-container>

    <!-- Spalte: TotalOvertimeTime (OVERTIME) -->
    <ng-container matColumnDef="TotalOvertimeTime">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        style="
          font-size: 16px;
          color: var(--font-color);
          text-align: center;
          padding: 0 12px;
        "
      >
        {{ "OVERTIME" | translate }}
      </mat-header-cell>
      <mat-cell
        *matCellDef="let element"
        style="text-align: center; padding: 0 12px"
      >
        {{ element.totalOvertimeTime }}
      </mat-cell>
    </ng-container>

    <!-- Spalte: ABWESENHEITSTAGE (absenceDays) -->
    <ng-container matColumnDef="AbsenceDays">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        style="
          font-size: 16px;
          color: var(--font-color);
          text-align: center;
          padding: 0 12px;
        "
      >
        {{ "ABSENT" | translate }}
      </mat-header-cell>
      <mat-cell
        *matCellDef="let element"
        style="text-align: center; padding: 0 12px"
      >
        {{ element.absenceDays }}
      </mat-cell>
    </ng-container>

    <!-- Actions (PDF/Excel) -->
    <ng-container matColumnDef="Actions">
      <mat-header-cell
        *matHeaderCellDef
        style="text-align: center; padding: 0 12px"
      >
      </mat-header-cell>
      <mat-cell
        style="
          text-align: center;
          padding: 0 12px;
          display: flex;
          justify-content: center;
          align-items: center;
        "
        *matCellDef="let element"
      >
        <button
          mat-icon-button
          [matMenuTriggerFor]="menu"
          aria-label="Example icon-button with a menu"
        >
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button
            mat-menu-item
            (click)="
              getEmployeeWorkHoursReportPdfQuery(
                element.Id,
                element.DepartmentId,
                element.EmployeeName
              )
            "
          >
            <i class="fa-solid fa-file-pdf"></i>
            <span> {{ "PDF REPORT" | translate }}</span>
          </button>
          <button
            mat-menu-item
            (click)="
              getEmployeeWorkHoursReportExcelQuery(
                element.Id,
                element.DepartmentId,
                element.EmployeeName
              )
            "
          >
            <i class="fa-solid fa-file-excel"></i>
            <span> {{ "EXCEL REPORT" | translate }}</span>
          </button>
        </mat-menu>
      </mat-cell>
    </ng-container>

    <!-- Header- und Daten-Zeilen -->
    <mat-header-row
      *matHeaderRowDef="displayedColumns; sticky: true"
      style="font-weight: 500; background-color: white; font: bold"
    ></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>

  <mat-paginator
    [length]="paginationService.totalCount"
    [pageSize]="paginationService.pageCount"
    [pageSizeOptions]="[5, 10, 15, 25, 100]"
    (page)="onPageSwitch($event)"
    class="mat-paginator-sticky"
  >
  </mat-paginator>
</div>
