<div class="row">
  <div class="form-group col-9">
    <label class="form-label">{{ "SHIFT PLANNING ACTIV" | translate }}</label>
  </div>
  <div class="form-group col-3">
    <label class="form-label" style="padding-left: 30px; padding-right: 10px">{{
      companySettings.ShiftSettings.ShiftPlanerActive
        ? ("YES" | translate)
        : ("NO" | translate)
    }}</label>
    <mat-slide-toggle
      color="primary"
      [checked]="companySettings.ShiftSettings.ShiftPlanerActive"
      (change)="onUpdate()"
      name="AllDay"
      [(ngModel)]="companySettings.ShiftSettings.ShiftPlanerActive"
    >
    </mat-slide-toggle>
  </div>
</div>
<hr class="mt-2 mb-3" />
<div
  class="row"
  [class.disabled]="!companySettings.ShiftSettings.ShiftPlanerActive"
>
  <div class="form-group col-9">
    <label class="form-label">{{ "ALLOW SHIFT SWAP" | translate }}</label>
  </div>
  <div class="form-group col-3">
    <label class="form-label" style="padding-left: 30px; padding-right: 10px">{{
      companySettings.ShiftSettings.AllowShiftSwap
        ? ("YES" | translate)
        : ("NO" | translate)
    }}</label>
    <mat-slide-toggle
      color="primary"
      [checked]="companySettings.ShiftSettings.AllowShiftSwap"
      (change)="onUpdate()"
      name="AllDay"
      [(ngModel)]="companySettings.ShiftSettings.AllowShiftSwap"
      [disabled]="!companySettings.ShiftSettings.ShiftPlanerActive"
    >
    </mat-slide-toggle>
  </div>
</div>
<div
  class="row"
  [class.disabled]="
    !companySettings.ShiftSettings.AllowShiftSwap ||
    !companySettings.ShiftSettings.ShiftPlanerActive
  "
>
  <div class="form-group col-8">
    <label class="form-label">{{
      "ADVANCE NOTICE FOR SHIFT SWAP" | translate
    }}</label>
  </div>
  <div class="form-group col-4">
    <app-input-editable
      showDropdownInput="true"
      [dropdownListe]="daysInAdvance"
      (onSave)="onUpdate()"
      [(ngModel)]="companySettings.ShiftSettings.DaysInAdvance"
    ></app-input-editable>
  </div>
</div>
