import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as dayjs from 'dayjs';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import {
  ApiEndPoints,
  ApiMethod,
  FileType,
} from '../../../core/services/const';
import { HttpService } from '../../../core/services/http/http.service';
import { ReportPaginationService } from '../component/report-list/report-pagination.service';
import { EmployeeWorkHoursDto } from '../model/employee-work-hours-dto';
import { EmployeeWorkHoursReportDto } from '../model/employee-work-hours.model';
import { PieChartDto } from '../model/pie-chart-dto.model';
import { TotalWorkHoursReportDto } from '../model/total-work-hours-report-dto.model';
@Injectable({
  providedIn: 'root',
})
export class ReportsService {
  constructor(
    private httpClient: HttpService,
    public paginationService: ReportPaginationService,
    private toastr: ToastrService,
    private translateService: TranslateService
  ) {}

  private employeeListSource: BehaviorSubject<EmployeeWorkHoursDto[]> =
    new BehaviorSubject([]);
  employeeList = this.employeeListSource.asObservable();

  private totalWorkHoursReportListSource: BehaviorSubject<TotalWorkHoursReportDto> =
    new BehaviorSubject(new TotalWorkHoursReportDto());
  totalWorkHoursReportList = this.totalWorkHoursReportListSource.asObservable();

  private getPieChartQuerySource: BehaviorSubject<PieChartDto> =
    new BehaviorSubject(new PieChartDto());
  getPieChartData = this.getPieChartQuerySource.asObservable();

  getEmployeeWorkHoursReport(
    startDate: string,
    endDate: string,
    employeeId: number,
    departmentIds: number[]
  ): void {
    const dateStart = dayjs(startDate).format('YYYY-MM-DD');
    const dateEnd = dayjs(endDate).format('YYYY-MM-DD');

    const postObject = {
      Pagination: this.paginationService.paginationModel,
      StartDate: dateStart,
      EndDate: dateEnd,
      EmployeeId: !(employeeId > 0) ? null : employeeId,
      DepartmentIds: departmentIds,
    };

    this.httpClient
      .requestCall(
        ApiEndPoints.GetEmployeeWorkHoursReportQuery,
        ApiMethod.POST,
        postObject
      )
      .subscribe((res) => {
        const employeeList = res as EmployeeWorkHoursReportDto;
        if (employeeList) {
          employeeList.DataList.forEach((employee) => {
            // Bisher
            employee.totalWorkTime =
              this.convertMinutesToLocalizedHoursAndMinutes(
                employee.TotalWorkMinutes
              );
            employee.totalOvertimeTime =
              this.convertMinutesToLocalizedHoursAndMinutes(
                employee.TotalOvertimeMinutes
              );

            // NEU:
            employee.targetWorkTime =
              this.convertMinutesToLocalizedHoursAndMinutes(
                employee.TargetWorkMinutes
              );
            employee.effectiveWorkTime =
              this.convertMinutesToLocalizedHoursAndMinutes(
                employee.EffectiveMinutes
              );
            employee.pureWorkTime =
              this.convertMinutesToLocalizedHoursAndMinutes(
                employee.PureWorkMinutes
              );
          });

          this.employeeListSource.next(employeeList.DataList);
          this.paginationService.setPaginationModel(employeeList.Pagination);
        }
      });
  }

  getTotalWorkHoursReportQuery(
    startDate: string,
    endDate: string,
    employeeId: number,
    departmentIds: number[]
  ): void {
    const dateStart = dayjs(startDate).utcOffset(0, true).format();
    const dateEnd = dayjs(endDate).utcOffset(0, true).format();

    const postObject = {
      StartDate: dateStart,
      EndDate: dateEnd,
      EmployeeId: !(employeeId > 0) ? null : employeeId,
      DepartmentIds: departmentIds,
    };

    this.httpClient
      .requestCall(
        ApiEndPoints.GetTotalWorkHoursReportQuery,
        ApiMethod.POST,
        postObject
      )
      .subscribe((res) => {
        const workReport = res as TotalWorkHoursReportDto;
        if (workReport) {
          workReport.totalWorkTime =
            this.convertMinutesToLocalizedHoursAndMinutes(
              workReport.TotalWorkMinutes
            );
          workReport.totalOvertimeTime =
            this.convertMinutesToLocalizedHoursAndMinutes(
              workReport.TotalOvertimeMinutes
            );

          this.totalWorkHoursReportListSource.next(workReport);
        }
      });
  }

  getPieChartQuery(
    startDate: string,
    endDate: string,
    employeeId: number,
    departmentIds: number[]
  ): void {
    const dateStart = dayjs(startDate).utcOffset(0, true).format();
    const dateEnd = dayjs(endDate).utcOffset(0, true).format();

    const postObject = {
      StartDate: dateStart,
      EndDate: dateEnd,
      EmployeeId: !(employeeId > 0) ? null : employeeId,
      DepartmentIds: departmentIds,
    };

    this.httpClient
      .requestCall(ApiEndPoints.GetPieChartQuery, ApiMethod.POST, postObject)
      .subscribe((res) => {
        const workReport = res as PieChartDto;
        if (workReport) {
          this.getPieChartQuerySource.next(workReport);
        }
      });
  }

  GetEmployeeWorkHoursReportQuery(
    startDate: string,
    endDate: string,
    employeeId: number,
    departmentIds: number[],
    fileType: FileType,
    employeeName?: string // optionaler Parameter
  ): void {
    const dateStart = dayjs(startDate).utcOffset(0, true).format('YYYY-MM-DD');
    const dateEnd = dayjs(endDate).utcOffset(0, true).format('YYYY-MM-DD');

    const postObject = {
      StartDate: dateStart,
      EndDate: dateEnd,
      EmployeeId: !(employeeId > 0) ? null : employeeId,
      DepartmentIds: departmentIds,
      Language: this.translateService.currentLang,
      FileType: fileType,
    };

    // HTTP-Aufruf
    this.httpClient
      .requestCall(
        ApiEndPoints.GenerateEmployeeWorkHoursReport,
        ApiMethod.POST,
        postObject,
        undefined,
        'blob'
      )
      .subscribe(
        (res: Blob) => {
          // Ab hier haben wir den Blob (PDF oder Excel).
          // Wir generieren einen sinnvollen Dateinamen, z.B.:
          //   "Bericht <employeeName> (01.12.2024-31.12.2024).pdf"

          const fileName = this.buildFileName(
            fileType,
            employeeName,
            startDate,
            endDate
          );
          this.downloadFile(res, fileType, fileName);
        },
        (error) => {
          console.error('Error downloading the file', error);
        }
      );
  }

  private buildFileName(
    fileType: FileType,
    employeeName: string,
    startDate: string,
    endDate: string
  ): string {
    // z.B. "2024-12-01" -> "01.12.2024"
    const sd = dayjs(startDate).format('DD.MM');
    const ed = dayjs(endDate).format('DD.MM');

    // Achtung: Sonderzeichen entfernen, die unter Windows/Linux problematisch sind
    const safeEmployeeName = this.sanitizeFileName(employeeName);

    const extension = fileType === FileType.Excel ? 'xlsx' : 'pdf';

    return `Bericht ${safeEmployeeName} (${sd}-${ed}).${extension}`;
  }

  private sanitizeFileName(input: string): string {
    // Entfernt z. B. / \ : ? * " < > |, etc.
    // Ein simpler Regex:
    return input.replace(/[\/\\\:\*\?\"\<\>\|]+/g, '_');
  }

  private downloadFile(data: Blob, fileType: FileType, fileName: string): void {
    if (fileType === FileType.Excel) {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName; // z.B. "Bericht Max Mustermann (01.12.2024-31.12.2024).xlsx"
      link.click();
      window.URL.revokeObjectURL(url);
    } else if (fileType === FileType.Pdf) {
      const blob = new Blob([data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName; // z.B. "Bericht Max Mustermann (01.12.2024-31.12.2024).pdf"
      link.click();
      window.URL.revokeObjectURL(url);
    }
  }

  private downloadPdfFromBase64(base64Data: string, fileName: string): void {
    const link = document.createElement('a');
    link.href = `data:application/pdf;base64,${base64Data}`;
    link.download = fileName;
    link.click();
  }

  private convertMinutesToLocalizedHoursAndMinutes(minutes: number): string {
    if (isNaN(minutes) || minutes === null || minutes === undefined) {
      // Falls das Feld leer ist, gib einfach "00:00" zurück
      return '00:00';
    }

    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    const hoursString = String(hours).padStart(2, '0');
    const minutesString = String(remainingMinutes).padStart(2, '0');

    return `${hoursString}:${minutesString}`;
  }
}
