<mat-tab-group>
  <!-- Arbeitszeiten Tab -->
  <mat-tab label="{{ 'WORK' | translate }}">
    <div class="tab-content">
      <table class="settings-table">
        <!-- ========== ÜBERSCHRIFT ========== -->
        <h5 class="sub-title" style="font-weight: 600">
          {{ "time_tracking" | translate }}
        </h5>

        <!-- ========== ENABLE TIME TRACKING ========== -->
        <tr>
          <td>
            <label
              class="form-label hover-bold"
              matTooltip="{{ 'TOOLTIP_ENABLE_TIME_TRACKING' | translate }}"
              >{{ "ENABLE TIME TRACKING" | translate }}</label
            >
          </td>
          <td>
            <div class="toggle-container">
              <label class="status-label">
                {{
                  companySettings.TimeTrackingSettings.TimeTrackingActive
                    ? ("YES" | translate)
                    : ("NO" | translate)
                }}
              </label>
              <mat-slide-toggle
                color="primary"
                [checked]="
                  companySettings.TimeTrackingSettings.TimeTrackingActive
                "
                (change)="onUpdate()"
                name="Flextime"
                [(ngModel)]="
                  companySettings.TimeTrackingSettings.TimeTrackingActive
                "
              >
              </mat-slide-toggle>
            </div>
          </td>
        </tr>

        <!-- ========== ALLOW MOBILE APP CLOCK IN (NEU) ========== -->
        <tr
          [class.disabled]="
            !companySettings.TimeTrackingSettings.TimeTrackingActive
          "
        >
          <td>
            <label
              class="form-label hover-bold"
              matTooltip="{{ 'TOOLTIP_ALLOW_MOBILE_APP_CLOCKIN' | translate }}"
              >{{ "ALLOW MOBILE APP CLOCK IN" | translate }}</label
            >
          </td>
          <td>
            <div class="toggle-container">
              <label class="status-label">
                {{
                  companySettings.TimeTrackingSettings.AllowMobileAppClockIn
                    ? ("YES" | translate)
                    : ("NO" | translate)
                }}
              </label>
              <mat-slide-toggle
                color="primary"
                [checked]="
                  companySettings.TimeTrackingSettings.AllowMobileAppClockIn
                "
                (change)="onUpdate()"
                name="AllowMobileAppClockIn"
                [(ngModel)]="
                  companySettings.TimeTrackingSettings.AllowMobileAppClockIn
                "
                [disabled]="
                  !companySettings.TimeTrackingSettings.TimeTrackingActive
                "
              >
              </mat-slide-toggle>
            </div>
          </td>
        </tr>

        <!-- ========== ENABLE TERMINAL (NEU) ========== -->
        <tr
          [class.disabled]="
            !companySettings.TimeTrackingSettings.TimeTrackingActive
          "
        >
          <td>
            <label
              class="form-label hover-bold"
              matTooltip="{{ 'TOOLTIP_ENABLE_TERMINAL' | translate }}"
              >{{ "ENABLE TERMINAL" | translate }}</label
            >
          </td>
          <td>
            <div class="toggle-container">
              <label class="status-label">
                {{
                  companySettings.TimeTrackingSettings.EnableTerminal
                    ? ("YES" | translate)
                    : ("NO" | translate)
                }}
              </label>
              <mat-slide-toggle
                color="primary"
                [checked]="companySettings.TimeTrackingSettings.EnableTerminal"
                (change)="onUpdate()"
                name="EnableTerminal"
                [(ngModel)]="
                  companySettings.TimeTrackingSettings.EnableTerminal
                "
                [disabled]="
                  !companySettings.TimeTrackingSettings.TimeTrackingActive
                "
              >
              </mat-slide-toggle>
            </div>
          </td>
        </tr>

        <!-- ========== AUTO-STOP AT MAX HOURS ========== -->
        <tr
          [class.disabled]="
            !companySettings.TimeTrackingSettings.TimeTrackingActive
          "
        >
          <td>
            <label
              class="form-label hover-bold"
              matTooltip="{{ 'TOOLTIP_AUTO_STOP_TIME' | translate }}"
              >{{ "AUTO-STOP AT MAX HOURS" | translate }}</label
            >
          </td>
          <td>
            <div class="toggle-container">
              <label class="status-label">
                {{
                  companySettings.TimeTrackingSettings.AutoStopTimeOnMaxHours
                    ? ("YES" | translate)
                    : ("NO" | translate)
                }}
              </label>
              <mat-slide-toggle
                color="primary"
                [checked]="
                  companySettings.TimeTrackingSettings.AutoStopTimeOnMaxHours
                "
                (change)="onUpdate()"
                name="Flextime"
                [(ngModel)]="
                  companySettings.TimeTrackingSettings.AutoStopTimeOnMaxHours
                "
                [disabled]="
                  !companySettings.TimeTrackingSettings.TimeTrackingActive
                "
              >
              </mat-slide-toggle>
            </div>
          </td>
        </tr>

        <!-- ========== OFFLINE FUNCTIONALITY ========== -->
        <tr
          [class.disabled]="
            !companySettings.TimeTrackingSettings.TimeTrackingActive
          "
        >
          <td>
            <label
              class="form-label hover-bold"
              matTooltip="{{ 'TOOLTIP_OFFLINE_FUNCTIONALITY' | translate }}"
              >{{ "OFFLINE FUNCTIONALITY" | translate }}</label
            >
          </td>
          <td>
            <div class="toggle-container">
              <label class="status-label">
                {{
                  companySettings.TimeTrackingSettings.OfflineTimeTracking
                    ? ("YES" | translate)
                    : ("NO" | translate)
                }}
              </label>
              <mat-slide-toggle
                color="primary"
                [checked]="
                  companySettings.TimeTrackingSettings.OfflineTimeTracking
                "
                (change)="onUpdate()"
                name="Flextime"
                [(ngModel)]="
                  companySettings.TimeTrackingSettings.OfflineTimeTracking
                "
                [disabled]="
                  !companySettings.TimeTrackingSettings.TimeTrackingActive
                "
              >
              </mat-slide-toggle>
            </div>
          </td>
        </tr>

        <!-- ========== GPS TRACKING ========== -->
        <tr
          [class.disabled]="
            !companySettings.TimeTrackingSettings.TimeTrackingActive
          "
        >
          <td>
            <label
              class="form-label hover-bold"
              matTooltip="{{ 'TOOLTIP_GPS_TRACKING' | translate }}"
              >{{ "GPS TRACKING" | translate }}</label
            >
          </td>
          <td>
            <div class="toggle-container">
              <label class="status-label">
                {{
                  companySettings.TimeTrackingSettings.GPSTracking
                    ? ("YES" | translate)
                    : ("NO" | translate)
                }}
              </label>
              <mat-slide-toggle
                color="primary"
                [checked]="companySettings.TimeTrackingSettings.GPSTracking"
                (change)="onUpdate()"
                name="Flextime"
                [(ngModel)]="companySettings.TimeTrackingSettings.GPSTracking"
                [disabled]="
                  !companySettings.TimeTrackingSettings.TimeTrackingActive
                "
              >
              </mat-slide-toggle>
            </div>
          </td>
        </tr>

        <hr />

        <!-- ========== ALLOW EMPLOYEE TO ADD ENTRIES ========== -->
        <tr
          [class.disabled]="
            !companySettings.TimeTrackingSettings.TimeTrackingActive
          "
        >
          <td>
            <label
              class="form-label hover-bold"
              matTooltip="{{ 'TOOLTIP_CAN_ADD' | translate }}"
              >{{ "ALLOW EMPLOYEE TO ADD ENTRIES" | translate }}</label
            >
          </td>
          <td>
            <div class="toggle-container">
              <label class="status-label">
                {{
                  companySettings.TimeTrackingSettings.EmployeeSettings
                    .CanAddTimeRecord
                    ? ("YES" | translate)
                    : ("NO" | translate)
                }}
              </label>
              <mat-slide-toggle
                color="primary"
                [checked]="
                  companySettings.TimeTrackingSettings.EmployeeSettings
                    .CanAddTimeRecord
                "
                (change)="onUpdate()"
                name="Flextime"
                [(ngModel)]="
                  companySettings.TimeTrackingSettings.EmployeeSettings
                    .CanAddTimeRecord
                "
                [disabled]="
                  !companySettings.TimeTrackingSettings.TimeTrackingActive
                "
              >
              </mat-slide-toggle>
            </div>
          </td>
        </tr>

        <!-- ========== ALLOW EMPLOYEE TO EDIT ENTRIES ========== -->
        <tr
          [class.disabled]="
            !companySettings.TimeTrackingSettings.TimeTrackingActive
          "
        >
          <td>
            <label
              class="form-label hover-bold"
              matTooltip="{{ 'TOOLTIP_CAN_EDIT' | translate }}"
              >{{ "ALLOW EMPLOYEE TO EDIT ENTRIES" | translate }}</label
            >
          </td>
          <td>
            <div class="toggle-container">
              <label class="status-label">
                {{
                  companySettings.TimeTrackingSettings.EmployeeSettings
                    .CanEditTimeRecord
                    ? ("YES" | translate)
                    : ("NO" | translate)
                }}
              </label>
              <mat-slide-toggle
                color="primary"
                [checked]="
                  companySettings.TimeTrackingSettings.EmployeeSettings
                    .CanEditTimeRecord
                "
                (change)="onUpdate()"
                name="Flextime"
                [(ngModel)]="
                  companySettings.TimeTrackingSettings.EmployeeSettings
                    .CanEditTimeRecord
                "
                [disabled]="
                  !companySettings.TimeTrackingSettings.TimeTrackingActive
                "
              >
              </mat-slide-toggle>
            </div>
          </td>
        </tr>

        <!-- ========== ALLOW EMPLOYEE TO DELETE ENTRIES ========== -->
        <tr
          [class.disabled]="
            !companySettings.TimeTrackingSettings.TimeTrackingActive
          "
        >
          <td>
            <label
              class="form-label hover-bold"
              matTooltip="{{ 'TOOLTIP_CAN_DELETE' | translate }}"
              >{{ "ALLOW EMPLOYEE TO DELETE ENTRIES" | translate }}</label
            >
          </td>
          <td>
            <div class="toggle-container">
              <label class="status-label">
                {{
                  companySettings.TimeTrackingSettings.EmployeeSettings
                    .CanDeleteTimeRecord
                    ? ("YES" | translate)
                    : ("NO" | translate)
                }}
              </label>
              <mat-slide-toggle
                color="primary"
                [checked]="
                  companySettings.TimeTrackingSettings.EmployeeSettings
                    .CanDeleteTimeRecord
                "
                (change)="onUpdate()"
                name="Flextime"
                [(ngModel)]="
                  companySettings.TimeTrackingSettings.EmployeeSettings
                    .CanDeleteTimeRecord
                "
                [disabled]="
                  !companySettings.TimeTrackingSettings.TimeTrackingActive
                "
              >
              </mat-slide-toggle>
            </div>
          </td>
        </tr>

        <hr class="mb-4" />

        <!-- ========== ÜBERSCHRIFT ========== -->
        <h5 class="sub-title" style="font-weight: 600">
          {{ "WORKING TIME REGULATIONS" | translate }}
        </h5>

        <!-- ========== MAX DAILY WORKING HOURS ========== -->
        <tr>
          <td style="width: 70%">
            <label
              class="form-label hover-bold"
              matTooltip="{{ 'TOOLTIP_MAX_DAILY_HOURS' | translate }}"
              >{{ "MAX DAILY WORKING HOURS" | translate }}</label
            >
          </td>
          <td style="width: 30%">
            <app-input-editable
              (onSave)="onUpdate()"
              showNormalInput="true"
              validatorsPattern="^(0|[1-9][0-9]?)$"
              [(ngModel)]="
                companySettings.TimeTrackingSettings.MaxDailyWorkingHours
              "
            ></app-input-editable>
          </td>
        </tr>

        <!-- ========== MAX WEEKLY WORKING HOURS ========== -->
        <tr>
          <td>
            <label
              class="form-label hover-bold"
              matTooltip="{{ 'TOOLTIP_MAX_WEEKLY_HOURS' | translate }}"
              >{{ "MAX WEEKLY WORKING HOURS" | translate }}</label
            >
          </td>
          <td>
            <app-input-editable
              (onSave)="onUpdate()"
              showNormalInput="true"
              validatorsPattern="^(0|[1-9][0-9]?)$"
              [(ngModel)]="
                companySettings.TimeTrackingSettings.MaxWeeklyWorkingHours
              "
            ></app-input-editable>
          </td>
        </tr>

        <!-- ========== MIN DAILY WORKING HOURS ========== -->
        <tr>
          <td>
            <label
              class="form-label hover-bold"
              matTooltip="{{ 'TOOLTIP_MIN_DAILY_HOURS' | translate }}"
              >{{ "MIN DAILY WORKING HOURS" | translate }}</label
            >
          </td>
          <td>
            <app-input-editable
              (onSave)="onUpdate()"
              showNormalInput="true"
              validatorsPattern="^(0|[1-9][0-9]?)$"
              [(ngModel)]="
                companySettings.TimeTrackingSettings.MinDailyWorkingHours
              "
            ></app-input-editable>
          </td>
        </tr>

        <!-- ========== MIN WEEKLY WORKING HOURS ========== -->
        <tr>
          <td>
            <label
              class="form-label hover-bold"
              matTooltip="{{ 'TOOLTIP_MIN_WEEKLY_HOURS' | translate }}"
              >{{ "MIN WEEKLY WORKING HOURS" | translate }}</label
            >
          </td>
          <td>
            <app-input-editable
              (onSave)="onUpdate()"
              showNormalInput="true"
              validatorsPattern="^(0|[1-9][0-9]?)$"
              [(ngModel)]="
                companySettings.TimeTrackingSettings.MinWeeklyWorkingHours
              "
            ></app-input-editable>
          </td>
        </tr>

        <!-- ========== MAX CONSECUTIVE WORK DAYS ========== -->
        <tr>
          <td>
            <label
              class="form-label hover-bold"
              matTooltip="{{ 'TOOLTIP_MAX_CONSECUTIVE_DAYS' | translate }}"
              >{{ "MAX CONSECUTIVE WORK DAYS" | translate }}</label
            >
          </td>
          <td>
            <app-input-editable
              (onSave)="onUpdate()"
              showNormalInput="true"
              validatorsPattern="^(0|[1-9][0-9]?)$"
              [(ngModel)]="
                companySettings.TimeTrackingSettings.MaxConsecutiveWorkDays
              "
            ></app-input-editable>
          </td>
        </tr>
      </table>
    </div>
  </mat-tab>

  <!-- Ruhe- und Pausenzeiten Tab -->
  <mat-tab label="{{ 'BREAK' | translate }}">
    <div class="tab-content">
      <table class="settings-table">
        <!-- ========== BREAK TIME ========== -->
        <tr>
          <td>
            <label
              class="form-label hover-bold"
              matTooltip="{{ 'TOOLTIP_BREAK_TIME' | translate }}"
              >{{ "BREAK TIME" | translate }}</label
            >
          </td>
          <td>
            <app-input-editable
              (onSave)="onUpdate()"
              showNormalInput="true"
              validatorsPattern="^(0|[1-9][0-9]?)$"
              [(ngModel)]="companySettings.TimeTrackingSettings.BreakTime"
            ></app-input-editable>
          </td>
        </tr>

        <!-- ========== AFTER HOURS ========== -->
        <tr>
          <td>
            <label
              class="form-label hover-bold"
              matTooltip="{{ 'TOOLTIP_AFTER_HOURS' | translate }}"
              >{{ "AFTER HOURS" | translate }}</label
            >
          </td>
          <td>
            <app-input-editable
              (onSave)="onUpdate()"
              showNormalInput="true"
              validatorsPattern="^(0|[1-9][0-9]?)$"
              [(ngModel)]="companySettings.TimeTrackingSettings.AfterHours"
            ></app-input-editable>
          </td>
        </tr>

        <!-- ========== DAILY REST PERIOD ========== -->
        <tr>
          <td>
            <label
              class="form-label hover-bold"
              matTooltip="{{ 'TOOLTIP_DAILY_REST' | translate }}"
              >{{ "DAILY REST PERIOD" | translate }}</label
            >
          </td>
          <td>
            <app-input-editable
              (onSave)="onUpdate()"
              showNormalInput="true"
              validatorsPattern="^(0|[1-9][0-9]?)$"
              [(ngModel)]="
                companySettings.TimeTrackingSettings.RestPeriods.DailyRestPeriod
              "
            ></app-input-editable>
          </td>
        </tr>

        <!-- ========== WEEKLY REST PERIOD ========== -->
        <tr>
          <td>
            <label
              class="form-label hover-bold"
              matTooltip="{{ 'TOOLTIP_WEEKLY_REST' | translate }}"
              >{{ "WEEKLY REST PERIOD" | translate }}</label
            >
          </td>
          <td>
            <app-input-editable
              (onSave)="onUpdate()"
              showNormalInput="true"
              validatorsPattern="^(0|[1-9][0-9]?)$"
              [(ngModel)]="
                companySettings.TimeTrackingSettings.RestPeriods
                  .WeeklyRestPeriod
              "
            ></app-input-editable>
          </td>
        </tr>

        <!-- ========== HOLIDAY REST PERIOD ========== -->
        <tr>
          <td>
            <label
              class="form-label hover-bold"
              matTooltip="{{ 'TOOLTIP_HOLIDAY_REST' | translate }}"
              >{{ "HOLIDAY REST PERIOD" | translate }}</label
            >
          </td>
          <td>
            <app-input-editable
              (onSave)="onUpdate()"
              showNormalInput="true"
              validatorsPattern="^(0|[1-9][0-9]?)$"
              [(ngModel)]="
                companySettings.TimeTrackingSettings.RestPeriods
                  .HolidayRestPeriod
              "
            ></app-input-editable>
          </td>
        </tr>
      </table>
    </div>
  </mat-tab>

  <!-- Gleitzeit und Überstunden Tab -->
  <mat-tab label="{{ 'FLEXTIME' | translate }}">
    <div class="tab-content">
      <table class="settings-table">
        <!-- ========== FLEXTIME ========== -->
        <tr>
          <td>
            <label
              class="form-label hover-bold"
              matTooltip="{{ 'TOOLTIP_FLEXTIME' | translate }}"
              >{{ "FLEXTIME" | translate }}</label
            >
          </td>
          <td>
            <div class="toggle-container">
              <label class="status-label">
                {{
                  companySettings.TimeTrackingSettings.Flextime.IsFlextime
                    ? ("YES" | translate)
                    : ("NO" | translate)
                }}
              </label>
              <mat-slide-toggle
                color="primary"
                [checked]="
                  companySettings.TimeTrackingSettings.Flextime.IsFlextime
                "
                (change)="onUpdate()"
                name="Flextime"
                [(ngModel)]="
                  companySettings.TimeTrackingSettings.Flextime.IsFlextime
                "
              >
              </mat-slide-toggle>
            </div>
          </td>
        </tr>

        <!-- ========== CORE TIME START (nur sichtbar wenn Flextime aktiv) ========== -->
        <tr *ngIf="companySettings.TimeTrackingSettings.Flextime.IsFlextime">
          <td>
            <label
              class="form-label hover-bold"
              matTooltip="{{ 'TOOLTIP_CORE_TIME_START' | translate }}"
              >{{ "CORE TIME START" | translate }}</label
            >
          </td>
          <td>
            <app-input-editable
              (onSave)="onUpdate()"
              showTimepicker="true"
              [(ngModel)]="
                companySettings.TimeTrackingSettings.Flextime.CoreTimeStart
              "
            ></app-input-editable>
          </td>
        </tr>

        <!-- ========== CORE TIME END (nur sichtbar wenn Flextime aktiv) ========== -->
        <tr *ngIf="companySettings.TimeTrackingSettings.Flextime.IsFlextime">
          <td>
            <label
              class="form-label hover-bold"
              matTooltip="{{ 'TOOLTIP_CORE_TIME_END' | translate }}"
              >{{ "CORE TIME END" | translate }}</label
            >
          </td>
          <td>
            <app-input-editable
              (onSave)="onUpdate()"
              showTimepicker="true"
              [(ngModel)]="
                companySettings.TimeTrackingSettings.Flextime.CoreTimeEnd
              "
            ></app-input-editable>
          </td>
        </tr>

        <!-- ========== OVERTIME ========== -->
        <tr>
          <td>
            <label
              class="form-label hover-bold"
              matTooltip="{{ 'TOOLTIP_OVERTIME' | translate }}"
              >{{ "OVERTIME" | translate }}</label
            >
          </td>
          <td>
            <div class="toggle-container">
              <label class="status-label">
                {{
                  companySettings.TimeTrackingSettings.Overtime.IsOvertime
                    ? ("YES" | translate)
                    : ("NO" | translate)
                }}
              </label>
              <mat-slide-toggle
                color="primary"
                [checked]="
                  companySettings.TimeTrackingSettings.Overtime.IsOvertime
                "
                (change)="onUpdate()"
                name="Flextime"
                [(ngModel)]="
                  companySettings.TimeTrackingSettings.Overtime.IsOvertime
                "
              >
              </mat-slide-toggle>
            </div>
          </td>
        </tr>

        <!-- ========== MAX OVERTIME HOURS (nur sichtbar wenn Overtime aktiv) ========== -->
        <tr *ngIf="companySettings.TimeTrackingSettings.Overtime.IsOvertime">
          <td>
            <label
              class="form-label hover-bold"
              matTooltip="{{ 'TOOLTIP_MAX_OVERTIME' | translate }}"
              >{{ "MAX OVERTIME HOURS" | translate }}</label
            >
          </td>
          <td>
            <app-input-editable
              (onSave)="onUpdate()"
              showNormalInput="true"
              validatorsPattern="^(0|[1-9][0-9]?)$"
              [(ngModel)]="
                companySettings.TimeTrackingSettings.Overtime.MaxOvertimeHours
              "
            ></app-input-editable>
          </td>
        </tr>
      </table>
    </div>
  </mat-tab>

  <!-- Benachrichtigungen und Genehmigungen Tab -->
  <mat-tab label="{{ 'NOTIFICATIONS' | translate }}">
    <div class="tab-content">
      <table class="settings-table">
        <!-- ========== NOTIFICATIONS ENABLE ========== -->
        <tr>
          <td>
            <label
              class="form-label hover-bold"
              matTooltip="{{ 'TOOLTIP_NOTIFICATIONS_ENABLE' | translate }}"
              >{{ "NOTIFICATIONS ENABLE" | translate }}</label
            >
          </td>
          <td>
            <label
              class="form-label"
              style="padding-left: 30px; padding-right: 10px"
            >
              {{
                companySettings.TimeTrackingSettings.Notifications.Enable
                  ? ("YES" | translate)
                  : ("NO" | translate)
              }}
            </label>
            <mat-slide-toggle
              color="primary"
              [checked]="
                companySettings.TimeTrackingSettings.Notifications.Enable
              "
              (change)="onUpdate()"
              name="Notifications"
              [(ngModel)]="
                companySettings.TimeTrackingSettings.Notifications.Enable
              "
            >
            </mat-slide-toggle>
          </td>
        </tr>

        <!-- ========== EMAIL NOTIFICATIONS ========== -->
        <tr>
          <td>
            <label
              class="form-label hover-bold"
              matTooltip="{{ 'TOOLTIP_EMAIL_NOTIFICATIONS' | translate }}"
              >{{ "EMAIL NOTIFICATIONS" | translate }}</label
            >
          </td>
          <td>
            <label
              class="form-label"
              style="padding-left: 30px; padding-right: 10px"
            >
              {{
                companySettings.TimeTrackingSettings.Notifications.Email
                  ? ("YES" | translate)
                  : ("NO" | translate)
              }}
            </label>
            <mat-slide-toggle
              color="primary"
              [checked]="
                companySettings.TimeTrackingSettings.Notifications.Email
              "
              (change)="onUpdate()"
              name="EmailNotifications"
              [(ngModel)]="
                companySettings.TimeTrackingSettings.Notifications.Email
              "
            >
            </mat-slide-toggle>
          </td>
        </tr>
      </table>
    </div>
  </mat-tab>
</mat-tab-group>
