<div class="terminal-container">
  <div class="top-section">
    <header class="header-section">
      <div class="logo-and-title"></div>

      <div class="time-and-date">
        <div class="time-label">{{ currentTime }}</div>
        <div class="date-label">{{ currentDate }}</div>
      </div>

      <button
        class="qr-button"
        (click)="onQrScan()"
        *ngIf="companySettingsService.CompanySettings.TerminalSettings.AllowQr"
      >
        <mat-icon>qr_code_scanner</mat-icon>
        QR-Scan
      </button>
    </header>

    <div class="search-bar">
      <mat-form-field appearance="fill" class="search-field">
        <mat-label>Suchen</mat-label>
        <input
          matInput
          [(ngModel)]="searchTerm"
          (ngModelChange)="onSearchChange()"
          placeholder="Name oder Kürzel..."
        />
        <button
          mat-icon-button
          matSuffix
          aria-label="Clear"
          *ngIf="searchTerm"
          (click)="searchTerm = ''; onSearchChange()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>
  </div>

  <div
    #employeeList
    class="employee-list"
    infiniteScroll
    [scrollWindow]="false"
    [infiniteScrollDistance]="2"
    [infiniteScrollThrottle]="100"
    (scrolled)="onScrollDown()"
  >
    <div class="employee-item" *ngFor="let emp of employees">
      <div class="employee-avatar">
        <div class="avatar-initials">
          {{ emp.FirstName.charAt(0) }}{{ emp.LastName.charAt(0) }}
        </div>
      </div>
      <div class="employee-name">{{ emp.FirstName }} {{ emp.LastName }}</div>

      <div class="employee-toggle">
        <!-- Neuer Button statt mat-slide-toggle -->
        <button class="btn btn-primary" (click)="onClockAction(emp)">
          {{ getClockButtonLabel(emp) }}
        </button>
      </div>
    </div>
  </div>
</div>
