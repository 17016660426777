import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  AfterViewInit,
  ChangeDetectorRef,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { AuthService } from '../../services/auth/auth.service';
import { DepartementService } from 'src/app/modules/departement/services/departement.service';
import {
  AddDepartement,
  DepartmentType,
  ProjectStatus,
} from 'src/app/modules/departement/model/add-departement';
import { AddSkill } from 'src/app/modules/skill/model/add-skill';
import { Employee } from 'src/app/modules/employee/model/employee.model';
import { CompanySettingsService } from 'src/app/modules/settings/services/company-settings.service';
import {
  CompanySettings,
  WorkStructure,
} from 'src/app/modules/settings/model/company-settings.model';
import { OnboardingProcessService } from '../../services/onboarding-process.service';
import { MatStepper } from '@angular/material/stepper';
import {
  CompanyDetails,
  CompanyService,
} from 'src/app/modules/settings/services/company.service';

@Component({
  selector: 'app-registered-stepper',
  templateUrl: './registered-stepper.component.html',
  styleUrls: ['./registered-stepper.component.css'],
})
export class RegisteredStepperComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild('stepper') private myStepper: MatStepper;

  structureFormGroup: FormGroup;

  completed: boolean = false; // Initial nicht abgeschlossen
  state = 'error';

  // Neue Subscription für isConfirmed
  private subscriptionIsConfirmed: Subscription;

  // Bestehende Subscriptions
  private subscriptionDepartmentList: Subscription;
  private subscriptionIconList: Subscription;
  private subscriptionSkillList: Subscription;
  private subscriptionIconTypeList: Subscription;

  // Schritt-Status
  structureStepCompleted = false;
  companyStepCompleted = false; // Neuer Schrittstatus für Company
  secondStepCompleted = false;
  thirdStepCompleted = false;
  fourthStepCompleted = false;

  // Schritt-Submit-Status
  companyStepSubmitted = false; // Neuer Submit-Status für Company
  secondStepSubmitted = false;
  thirdStepSubmitted = false;
  fourthStepSubmitted = false;

  // Formmodelle
  formModelDepartement: AddDepartement = {
    Id: 0,
    Name: '',
    Type: DepartmentType.Department,
    ParentDepartmentId: null,
    ProjectStart: null,
    ProjectEnd: null,
    IsProjectCompleted: false,
    Description: '',
    Status: ProjectStatus.Planned,
    ProjectManagerId: null,
    SkillIds: [],
  };

  companyDetails: CompanyDetails; // Neues Formmodell für Company

  isSaveSuccessful = true;
  isConfirmed$: Observable<boolean>;
  structureDecisionText: SafeHtml;

  WorkStructureEnum = WorkStructure;
  companySettings: CompanySettings;

  // Anzahl der Schritte im Stepper (erhöht um 1 für den neuen Schritt)
  stepperStepsCount = 7;

  constructor(
    private _formBuilder: FormBuilder,
    public departementService: DepartementService,
    private translate: TranslateService,
    public router: Router,
    public authService: AuthService,
    private sanitizer: DomSanitizer,
    public companySettingsService: CompanySettingsService,
    private onboardingService: OnboardingProcessService,
    private cdr: ChangeDetectorRef,
    private companyService: CompanyService // Hinzufügen des CompanyService
  ) {}

  ngOnInit() {
    // Abonnieren der CompanySettings
    this.companySettingsService.CompanySettingsObservable.subscribe(
      (settings) => {
        if (settings) {
          this.companySettings = settings;
        }
      }
    );

    // Initialisieren der FormGroups
    this.structureFormGroup = this._formBuilder.group({
      structureCtrl: [
        this.companySettings?.WorkStructure || WorkStructure.Departments,
        Validators.required,
      ],
    });

    // Abonnieren der Bestätigungsstatus
    this.subscriptionIsConfirmed = this.authService
      .isEmailConfirmed()
      .subscribe(
        (confirmed: boolean) => {
          this.isConfirmed$ = new Observable<boolean>((observer) => {
            observer.next(confirmed);
            observer.complete();
          });
        },
        (error) => {
          console.error('Error fetching confirmation status', error);
          this.isConfirmed$ = new Observable<boolean>((observer) => {
            observer.next(false);
            observer.complete();
          });
        }
      );

    // Übersetzungen laden und sanitizen
    this.translate
      .get(['STRUCTURE_DECISION_TITLE', 'STRUCTURE_DECISION_TEXT'])
      .subscribe((translations) => {
        this.structureDecisionText = this.sanitizer.bypassSecurityTrustHtml(
          translations['STRUCTURE_DECISION_TEXT']
        );
      });

    // Initialisieren der CompanySettings
    this.companySettingsService.getCompanySettings();

    // Laden der CompanyDetails
    this.companyService.companyDetails$.subscribe((data) => {
      if (data) {
        this.companyDetails = data;
      }
    });
    this.companyService.getCompanyDetails();
  }

  selectStructure(structure: WorkStructure) {
    this.structureFormGroup.get('structureCtrl')?.setValue(structure);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.onboardingService.getCurrentStepper().subscribe({
        next: (currentStep) => {
          if (currentStep > 0 && currentStep <= this.stepperStepsCount) {
            this.markStepsAsCompleted(currentStep);
            // run this after 100ms to avoid ExpressionChangedAfterItHasBeenCheckedError
            setTimeout(() => {
              if (this.myStepper.steps.length < currentStep) {
                return;
              }

              this.myStepper.selectedIndex = currentStep;
              this.cdr.detectChanges();
            }, 10);
          }
        },
        error: (error) => {
          console.error('Error fetching current stepper:', error);
        },
      });
    }, 10);
  }

  /**
   * Markiert die vorherigen Schritte als abgeschlossen basierend auf dem aktuellen Stepper-Wert.
   * @param currentStep Der aktuelle Stepper-Wert.
   */
  private markStepsAsCompleted(currentStep: number): void {
    this.structureStepCompleted = currentStep > 1; // Schritt 1 abgeschlossen, wenn currentStep > 1
    this.companyStepCompleted = currentStep > 2; // Neuer Schrittstatus
    this.secondStepCompleted = currentStep > 3; // Schritt 2 abgeschlossen, wenn currentStep > 3
    this.thirdStepCompleted = currentStep > 4; // Schritt 3 abgeschlossen, wenn currentStep > 4
    this.fourthStepCompleted = currentStep > 5; // Schritt 4 abgeschlossen, wenn currentStep > 5
  }

  ngOnDestroy(): void {
    // Alle Subscriptions sauber beenden
    if (this.subscriptionIsConfirmed) {
      this.subscriptionIsConfirmed.unsubscribe();
    }
    if (this.subscriptionDepartmentList) {
      this.subscriptionDepartmentList.unsubscribe();
    }
    if (this.subscriptionIconList) {
      this.subscriptionIconList.unsubscribe();
    }
    if (this.subscriptionSkillList) {
      this.subscriptionSkillList.unsubscribe();
    }
    if (this.subscriptionIconTypeList) {
      this.subscriptionIconTypeList.unsubscribe();
    }
  }

  onFirstStepNextButtonClick() {
    // Schritt 1 abgeschlossen
    this.onboardingService.updateCurrentStepper(1);
  }

  onCompanySubmit(formValid: boolean): void {
    if (formValid) {
      this.companyStepSubmitted = true;
      this.companyStepCompleted = true;
      setTimeout(() => {
        this.myStepper.next();
        this.onboardingService.updateCurrentStepper(3); // Update auf Schritt 3
      }, 100);
    }
  }

  done() {
    this.completed = true;
    this.onboardingService.updateCurrentStepper(7);
    this.state = 'done';
    this.router.navigateByUrl('/settings');
  }

  onDepartementSubmit(form: AddDepartement): void {
    if (form) {
      this.thirdStepSubmitted = true;
      this.thirdStepCompleted = true;
      // nach 100ms zum nächsten Schritt gehen
      setTimeout(() => {
        this.myStepper.next();
        this.onboardingService.updateCurrentStepper(5); // Update auf Schritt 5
        this.departementService.getDepartementList();
      }, 100);
    }
  }

  onSkillSubmit(form: AddSkill): void {
    if (form) {
      this.secondStepSubmitted = true;
      this.secondStepCompleted = true;
      setTimeout(() => {
        this.myStepper.next();
        this.onboardingService.updateCurrentStepper(4); // Update auf Schritt 4
      }, 100);
    }
  }

  onEmployeeSubmit(form: Employee): void {
    if (form) {
      this.fourthStepSubmitted = true;
      this.fourthStepCompleted = true;
      setTimeout(() => {
        this.myStepper.next();
        this.onboardingService.updateCurrentStepper(6); // Update auf Schritt 6
      }, 100);
    }
  }

  getDepartements() {
    this.departementService.getDepartementList();
  }

  onSecondFormGroupNextButtonClick() {
    const selectedValue = this.structureFormGroup.get('structureCtrl')
      .value as WorkStructure;
    this.getDepartements();
    this.structureStepCompleted = true;

    setTimeout(() => {
      this.myStepper.next();
      // Aktualisieren der WorkStructure in CompanySettings
      this.updateWorkStructure(selectedValue);
      this.onboardingService.updateCurrentStepper(2); // Update auf Schritt 2
    }, 100);
  }

  updateWorkStructure(workStructure: WorkStructure) {
    this.companySettingsService.CompanySettings.WorkStructure = workStructure;
    this.companySettingsService.updateCompanySettings(
      this.companySettingsService.CompanySettings
    );
  }
}
