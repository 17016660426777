import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ConfirmationDialogComponent } from 'src/app/core/component/confirmation-dialog/confirmation-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { TerminalService, TerminalDto } from '../../services/terminal.service';
import { AddTerminalComponent } from '../app-add-terminal/add-terminal.component';

@Component({
  selector: 'app-terminal-list',
  templateUrl: './terminal-list.component.html',
  styleUrls: ['./terminal-list.component.css'],
})
export class TerminalListComponent implements OnInit, OnDestroy {
  private subs = new Subscription();
  terminalList: TerminalDto[] = [];
  dialogRef: MatDialogRef<ConfirmationDialogComponent>;

  constructor(
    private service: TerminalService,
    private dialog: MatDialog,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.loadTerminals();
    this.service.getAllTerminals();
  }

  loadTerminals(): void {
    this.subs.add(
      this.service.terminalsList$.subscribe({
        next: (list) => {
          this.terminalList = list;
        },
        error: (err) => {
          console.error('Fehler beim Laden der Terminals', err);
        },
      })
    );
  }

  onDeleteTerminal(userName: string, email: string): void {
    // Bestätigungsdialog öffnen
    this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false,
      panelClass: 'custom-dialog-container',
    });

    const dialogText = `${this.translate.instant(
      'ARE_YOU_SURE_DELETE_TERMINAL'
    )}<br/><b>${userName} (${email})</b>?`;

    this.dialogRef.componentInstance.confirmMessage = dialogText;

    this.dialogRef.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        // Terminal via Service löschen
        this.subs.add(
          this.service.deleteTerminal(userName).subscribe({
            next: () => {
              // Nach Erfolg Liste aktualisieren
              this.service.getAllTerminals();
            },
            error: (err) => {
              console.error('Fehler beim Löschen des Terminals', err);
            },
          })
        );
      }
      this.dialogRef = null;
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
