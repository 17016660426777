<div class="tab-content">
  <h5 class="sub-title" style="font-weight: 600">
    {{ "TITLE_SETTINGS" | translate }}
  </h5>

  <table class="settings-table">
    <!-- ALLOW PIN -->
    <tr>
      <td>
        <label
          class="form-label hover-bold"
          matTooltip="{{ 'TOOLTIP_ALLOW_PIN' | translate }}"
          >{{ "ALLOW_PIN" | translate }}</label
        >
      </td>
      <td>
        <div class="toggle-container">
          <label class="status-label">
            {{
              companySettings.TerminalSettings.AllowPin
                ? ("YES" | translate)
                : ("NO" | translate)
            }}
          </label>
          <mat-slide-toggle
            color="primary"
            [checked]="companySettings.TerminalSettings.AllowPin"
            (change)="onUpdate()"
            [(ngModel)]="companySettings.TerminalSettings.AllowPin"
            name="AllowPin"
          >
          </mat-slide-toggle>
        </div>
      </td>
    </tr>

    <!-- ALLOW NFC -->
    <tr>
      <td>
        <label
          class="form-label hover-bold"
          matTooltip="{{ 'TOOLTIP_ALLOW_NFC' | translate }}"
          >{{ "ALLOW_NFC" | translate }}</label
        >
      </td>
      <td>
        <div class="toggle-container">
          <label class="status-label">
            {{
              companySettings.TerminalSettings.AllowNfc
                ? ("YES" | translate)
                : ("NO" | translate)
            }}
          </label>
          <mat-slide-toggle
            color="primary"
            [checked]="companySettings.TerminalSettings.AllowNfc"
            (change)="onUpdate()"
            [(ngModel)]="companySettings.TerminalSettings.AllowNfc"
            name="AllowNfc"
          >
          </mat-slide-toggle>
        </div>
      </td>
    </tr>

    <!-- ALLOW QR -->
    <tr>
      <td>
        <label
          class="form-label hover-bold"
          matTooltip="{{ 'TOOLTIP_ALLOW_QR' | translate }}"
          >{{ "ALLOW_QR" | translate }}</label
        >
      </td>
      <td>
        <div class="toggle-container">
          <label class="status-label">
            {{
              companySettings.TerminalSettings.AllowQr
                ? ("YES" | translate)
                : ("NO" | translate)
            }}
          </label>
          <mat-slide-toggle
            color="primary"
            [checked]="companySettings.TerminalSettings.AllowQr"
            (change)="onUpdate()"
            [(ngModel)]="companySettings.TerminalSettings.AllowQr"
            name="AllowQr"
          >
          </mat-slide-toggle>
        </div>
      </td>
    </tr>

    <!-- PIN LENGTH -->
    <tr>
      <td>
        <label
          class="form-label hover-bold"
          matTooltip="{{ 'TOOLTIP_PIN_LENGTH' | translate }}"
          >{{ "PIN_LENGTH" | translate }}</label
        >
      </td>
      <td>
        <app-input-editable
          (onSave)="onUpdate()"
          showNormalInput="true"
          validatorsPattern="^(4|5|6|7|8|9)$"
          [(ngModel)]="companySettings.TerminalSettings.PinLength"
        ></app-input-editable>
      </td>
    </tr>

    <hr />

    <!-- PIN-Pad TIMEOUT -->
    <tr>
      <td>
        <label
          class="form-label hover-bold"
          matTooltip="{{ 'TOOLTIP_PINPAD_TIMEOUT' | translate }}"
          >{{ "PINPAD_TIMEOUT" | translate }}</label
        >
      </td>
      <td>
        <app-input-editable
          (onSave)="onUpdate()"
          showNormalInput="true"
          validatorsPattern="^[0-9]+$"
          [(ngModel)]="companySettings.TerminalSettings.PinPadInactivityTimeout"
        ></app-input-editable>
      </td>
    </tr>

    <!-- SEARCH RESET TIMEOUT -->
    <tr>
      <td>
        <label
          class="form-label hover-bold"
          matTooltip="{{ 'TOOLTIP_SEARCH_RESET_TIMEOUT' | translate }}"
          >{{ "SEARCH_RESET_TIMEOUT" | translate }}</label
        >
      </td>
      <td>
        <app-input-editable
          (onSave)="onUpdate()"
          showNormalInput="true"
          validatorsPattern="^[0-9]+$"
          [(ngModel)]="
            companySettings.TerminalSettings.SearchResetInactivityTimeout
          "
        ></app-input-editable>
      </td>
    </tr>

    <hr />

    <!-- SHOW EMPLOYEE LIST -->
    <!-- <tr>
      <td>
        <label
          class="form-label hover-bold"
          matTooltip="{{ 'TOOLTIP_SHOW_EMPLOYEE_LIST' | translate }}"
          >{{ "SHOW_EMPLOYEE_LIST" | translate }}</label
        >
      </td>
      <td>
        <div class="toggle-container">
          <label class="status-label">
            {{
              companySettings.TerminalSettings.ShowEmployeeList
                ? ("YES" | translate)
                : ("NO" | translate)
            }}
          </label>
          <mat-slide-toggle
            color="primary"
            [checked]="companySettings.TerminalSettings.ShowEmployeeList"
            (change)="onUpdate()"
            [(ngModel)]="companySettings.TerminalSettings.ShowEmployeeList"
            name="ShowEmployeeList"
          >
          </mat-slide-toggle>
        </div>
      </td>
    </tr> -->

    <!-- SHOW EMPLOYEE STATUS -->
    <tr>
      <td>
        <label
          class="form-label hover-bold"
          matTooltip="{{ 'TOOLTIP_SHOW_EMPLOYEE_STATUS' | translate }}"
          >{{ "SHOW_EMPLOYEE_STATUS" | translate }}</label
        >
      </td>
      <td>
        <div class="toggle-container">
          <label class="status-label">
            {{
              companySettings.TerminalSettings.ShowEmployeeStatus
                ? ("YES" | translate)
                : ("NO" | translate)
            }}
          </label>
          <mat-slide-toggle
            color="primary"
            [checked]="companySettings.TerminalSettings.ShowEmployeeStatus"
            (change)="onUpdate()"
            [(ngModel)]="companySettings.TerminalSettings.ShowEmployeeStatus"
            name="ShowEmployeeStatus"
          >
          </mat-slide-toggle>
        </div>
      </td>
    </tr>
  </table>
</div>
