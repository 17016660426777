<div
  class="modal-dialog modal-lg"
  role="document"
  style="margin: 10px"
  (click)="onBackdropClick()"
>
  <div
    class="modal-content"
    (click)="$event.stopPropagation()"
    style="
      width: fit-content;
      min-width: 310px;
      max-width: 500px;
      min-height: 300px;
    "
  >
    <div class="modal-header" style="margin-bottom: 0px; padding-bottom: 0px">
      <div class="modal-title w-100 row">
        <div class="col-2">
          <span
            class="badge badge-pill badge-primary align-middle"
            style="border-radius: 10px; padding-top: 15px; padding-bottom: 15px"
            [style.background]="timeIconBadgeColor"
          >
            <i
              class="fa-light fa-clock fa-2xl"
              [style.color]="timeIconColor"
            ></i>
          </span>
        </div>
        <div style="padding-left: 15px" class="col">
          <p style="margin: 0px; font-size: small">{{ "TODAY" | translate }}</p>
          <ng-container *ngIf="dataLoaded; else loadingSkeleton">
            <h5>{{ workedTimeDisplay }}</h5>
          </ng-container>
          <ng-template #loadingSkeleton>
            <div class="skeleton-loader" style="width: 100px; height: 24px">
              <h5>00:00:00</h5>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
    <hr class="mt-2 mb-4" style="padding-bottom: 20px" />
    <!-- Fehlernachrichten als Alerts -->
    <div
      *ngIf="ComplianceMessage && ComplianceMessage.length > 0"
      style="margin: 5px"
    >
      <div
        *ngFor="let message of ComplianceMessage"
        class="alert alert-warning d-flex align-items-center"
        role="alert"
        style="padding: 5px; font-size: 13px"
      >
        <i
          class="fa fa-exclamation-triangle fa-xl mr-2"
          style="padding-right: 10px"
        ></i>
        <span>{{
          message[0] | translate : { value1: message[1], value2: message[2] }
        }}</span>
      </div>
    </div>

    <div class="modal-body">
      <div *ngIf="showDepartmentSelector" class="form-group mb-4">
        <label for="Department">{{
          this.companySettingsService.CompanySettings.WorkStructure ===
          workStructureEnum.Departments
            ? "Department"
            : ((this.companySettingsService.CompanySettings.WorkStructure ===
              workStructureEnum.Projects
                ? "Project"
                : "DEPARTMENT/PROJECT"
              ) | translate)
        }}</label>
        <mat-select
          id="Department"
          class="form-control"
          placeholder="{{ 'Select Department' | translate }}"
          (selectionChange)="onDepartmentChange($event)"
          [formControl]="departmentValue"
          [(ngModel)]="departmentSelectionId"
          (selectionChange)="updateSliderDisabled()"
          [ngClass]="{ 'required-error': departmentSelectionInvalid }"
          required
        >
          <mat-option
            *ngFor="let department of employeeDepartment"
            [value]="department.Id"
          >
            {{ department.Name }}
          </mat-option>
        </mat-select>
      </div>
      <div *ngIf="showSkillSelector" class="form-group mb-4">
        <label for="Department">{{ "QUALIFICATION" | translate }}</label>
        <mat-select
          id="Department"
          class="form-control"
          placeholder="{{ skillPlaceholderText }}"
          [formControl]="skillValue"
          [(ngModel)]="skillSelectionId"
          (selectionChange)="updateSliderDisabled()"
        >
          <mat-option
            *ngFor="let skill of employeeSkillList"
            [value]="skill.Id"
          >
            <i [ngClass]="skill.IconCode"></i> {{ skill.Name }}
          </mat-option>
        </mat-select>
      </div>
      <!-- Slider Button -->
      <div class="slider-container" #slider>
        <div class="slider-track-green" #sliderTrackGreen></div>
        <div class="slider-track-red" #sliderTrackRed></div>
        <div
          class="slider-handle"
          #handle
          (mousedown)="startDrag($event)"
          (touchstart)="startDrag($event)"
        >
          <i
            *ngIf="currentTimeStatus === 0 || currentTimeStatus === 2"
            class="fa-light fa-circle-play"
          ></i>
          <i *ngIf="currentTimeStatus === 1" class="fa-light fa-play-pause"></i>
        </div>
        <span class="slider-text" #sliderTextStart>
          <p *ngIf="departmentSelectionId === 0">
            {{ "Select Department" | translate }}
          </p>
          <p
            *ngIf="
              !sliderDisabled &&
              (currentTimeStatus === 0 || currentTimeStatus === 2)
            "
          >
            {{ "SWIPE TO CLOCK IN" | translate }}
          </p>
        </span>
        <span class="slider-text" #sliderTextStop>
          <p *ngIf="currentTimeStatus === 1">{{ "STOP TIME" | translate }}</p>
        </span>
      </div>
      <div class="scrollable-accordion-container">
        <app-time-recording-accordion
          (clockEvent)="handleClockEvent($event)"
          [eventListAccordion]="eventListAccordion"
          *ngIf="eventListAccordion.length"
        >
        </app-time-recording-accordion>
      </div>
    </div>
    <div class="row" style="padding-top: 25px">
      <app-day-week-month-chart
        [showMonth]="false"
        [employeeId]="employeeId"
      ></app-day-week-month-chart>
    </div>
  </div>
</div>
