import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { fromEvent, merge, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { CompanySettingsService } from 'src/app/modules/settings/services/company-settings.service';

@Component({
  selector: 'app-pin-pad-dialog',
  templateUrl: './pin-pad-dialog.component.html',
  styleUrls: ['./pin-pad-dialog.component.css'],
})
export class PinPadDialogComponent implements OnInit, OnDestroy {
  pinInput: string = '';

  private inactivityTimer: any;
  private globalEventsSub: Subscription;

  constructor(
    public dialogRef: MatDialogRef<PinPadDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private companySettingsService: CompanySettingsService
  ) {}

  ngOnInit(): void {
    // Hier lesen wir den Timeout aus den CompanySettings:
    const inactivitySecs =
      this.companySettingsService.CompanySettings.TerminalSettings
        .PinPadInactivityTimeout ?? 15; // fallback

    // Start Inaktivitätstimer
    this.resetInactivityTimer(inactivitySecs);

    // Globale Events (Maus, Touch, Key) => Timer-Reset
    const mousemove$ = fromEvent(document, 'mousemove');
    const click$ = fromEvent(document, 'click');
    const keydown$ = fromEvent(document, 'keydown');
    const touchstart$ = fromEvent(document, 'touchstart');

    this.globalEventsSub = merge(mousemove$, click$, keydown$, touchstart$)
      .pipe(debounceTime(300))
      .subscribe(() => {
        this.resetInactivityTimer(inactivitySecs);
      });
  }

  ngOnDestroy(): void {
    if (this.inactivityTimer) {
      clearTimeout(this.inactivityTimer);
    }
    if (this.globalEventsSub) {
      this.globalEventsSub.unsubscribe();
    }
  }

  private resetInactivityTimer(inactivitySecs: number): void {
    if (this.inactivityTimer) {
      clearTimeout(this.inactivityTimer);
    }
    this.inactivityTimer = setTimeout(() => {
      this.dialogRef.close(null);
    }, inactivitySecs * 1000);
  }

  // --- PinPad-Logik ---
  onNumberClick(num: string): void {
    if (this.pinInput.length < 6) {
      this.pinInput += num;
    }
  }

  onDelete(): void {
    if (this.pinInput.length > 0) {
      this.pinInput = this.pinInput.slice(0, -1);
    }
  }

  onClear(): void {
    this.pinInput = '';
  }

  onConfirm(): void {
    this.dialogRef.close(this.pinInput);
  }

  onCancel(): void {
    this.dialogRef.close(null);
  }

  get pinArray(): string[] {
    return this.pinInput.split('');
  }
}
