export class TerminalSettings {
  PinPadInactivityTimeout: number = 15;
  SearchResetInactivityTimeout: number = 15;
  AllowPin: boolean = true;
  AllowNfc: boolean = false;
  AllowQr: boolean = false;
  PinLength: number = 4;
  ShowEmployeeList: boolean = true;
  ShowEmployeeStatus: boolean = true;
}
