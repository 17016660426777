import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ClockEventType, CreatedByType } from 'src/app/core/services/const';
import { Departement } from 'src/app/modules/departement/model/departement';
import {
  DepartmentDto,
  EmployeeDetailsDto,
  SkillDto,
} from 'src/app/modules/employee/model/employee-details-dto.model';
import { EmployeeService } from 'src/app/modules/employee/services/employee.service';
import { ClockEventOneDayDto } from '../../model/clock-event-dto.model';
import { TimeRecordingService } from '../../services/time-recording.service';
import { CompanySettingsService } from 'src/app/modules/settings/services/company-settings.service';
import { WorkStructure } from 'src/app/modules/settings/model/company-settings.model';
import { co } from '@fullcalendar/core/internal-common';
import { ToastrService } from 'ngx-toastr';
import { EmployeeLastStatusDto } from '../../model/employee-last-status-dto.model';

/** INAKTIVITÄT: Zusätzliche Importe */
import { fromEvent, merge } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-time-dialog',
  templateUrl: './time-dialog.component.html',
  styleUrls: ['./time-dialog.component.css'],
})
export class TimeDialogComponent implements AfterViewInit, OnDestroy {
  @ViewChild('slider', { static: true }) slider: ElementRef;
  @ViewChild('handle', { static: true }) handle: ElementRef;
  @ViewChild('sliderTrackGreen', { static: true }) sliderTrackGreen: ElementRef;
  @ViewChild('sliderTrackRed', { static: true }) sliderTrackRed: ElementRef;
  @ViewChild('sliderTextStart', { static: false }) sliderTextStart: ElementRef;
  @ViewChild('sliderTextStop', { static: false }) sliderTextStop: ElementRef;

  panelOpenState = false;
  currentTimeStatus: ClockEventType = ClockEventType.Nothing;
  workStructureEnum = WorkStructure;

  LastEventTime: Date = new Date();
  TotalSecondsWorkedToday: number = 0;
  workedTimeDisplay: string = '00:00:00';
  timer: any;
  LastEventTimeString: string;
  eventListAccordion: any[] = [];

  redBackground = 'linear-gradient(135deg, #e332613b 0%, #fb648cff 100%)';
  greenBackground = 'linear-gradient(135deg, #4bcc8141 0%, #4bcc81d7 100%)';
  timeIconBadgeColor = this.redBackground;
  timeIconColor = '#7d0726ff';

  private subscriptions: Subscription[] = [];
  showDepartmentSelector: boolean = false;
  showSkillSelector: boolean = false;
  skillList: any[];
  skillPlaceholderText: any;
  departmentList: Departement[];

  departmentValue = new UntypedFormControl();
  skillValue = new UntypedFormControl();
  employeeDepartment: DepartmentDto[] = [];
  employeeSkillList: SkillDto[] = [];
  employeeSkillListTemp: SkillDto[] = [];

  departmentSelectionId: number = 0;
  skillSelectionId: number = 0;
  sliderDisabled: boolean = true;
  ComplianceMessage: any[];
  dataLoaded: boolean = false;

  // Neues Flag für Invalid-Zustand
  departmentSelectionInvalid: boolean = false;
  employeeId: number = 0;

  /** INAKTIVITÄT: Zusätzliche Felder */
  private inactivityTimer: any;
  private globalEventsSub: Subscription;

  constructor(
    public dialogRef: MatDialogRef<TimeDialogComponent>,
    private renderer: Renderer2,
    private timeRecordingService: TimeRecordingService,
    private employeeService: EmployeeService,
    private translate: TranslateService,
    public companySettingsService: CompanySettingsService,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit(): void {
    const employeeStatusSub =
      this.timeRecordingService.employeeStatus.subscribe(
        (status: EmployeeLastStatusDto) => {
          if (status) {
            this.currentTimeStatus = status.LastEventType;
            this.updateIconColors();
            this.LastEventTime = new Date(status.LastEventTime);
            this.updateLastEventTimeString();
            this.TotalSecondsWorkedToday = status.TotalSecondsWorkedToday;
            this.ComplianceMessage = status.ComplianceMessage;
            this.eventListAccordion =
              status.ClockEventOneDayDto.ClockEvents.length > 0
                ? [status.ClockEventOneDayDto]
                : [];
            if (this.currentTimeStatus == ClockEventType.ClockIn) {
              this.skillSelectionId = status.SkillId;
              this.departmentSelectionId = status.DepartmentId;
              this.startTimer();
            } else {
              this.workedTimeDisplay = this.formatTime(
                this.TotalSecondsWorkedToday
              );
            }
            this.updateFormControls();
            this.dataLoaded = true;
          }
        }
      );

    this.subscriptions.push(employeeStatusSub);

    if (this.data && this.data.employeeId) {
      this.employeeId = this.data.employeeId;
      this.employeeService.GetEmployeeDetails(this.data.employeeId);
      this.timeRecordingService.getEmployeeLastStatus(this.data.employeeId);
    } else {
      this.employeeService.GetEmployeeDetails();
      this.timeRecordingService.getEmployeeLastStatus();
    }

    this.employeeService.getEmployeeDetails.subscribe(
      (res: EmployeeDetailsDto) => {
        if (res.DepartmentList && res.DepartmentList.length > 1) {
          this.showDepartmentSelector = true;
          this.employeeDepartment = res.DepartmentList;
          this.skillValue.disable();
        } else if (res.DepartmentList && res.DepartmentList.length == 1) {
          this.departmentSelectionId = res.DepartmentList[0].Id;
          this.showDepartmentSelector = false;
        }

        if (res.SkillList && res.SkillList.length > 1) {
          this.employeeSkillListTemp = res.SkillList;
          this.employeeSkillList = res.SkillList;
          this.showSkillSelector = true;

          // Überprüfen, ob jede Abteilung nur eine Fähigkeit hat
          const departmentSkillCount = new Map<number, number>();
          res.SkillList.forEach((skill) => {
            skill.Departments.forEach((department) => {
              if (!departmentSkillCount.has(department.Id)) {
                departmentSkillCount.set(department.Id, 0);
              }
              departmentSkillCount.set(
                department.Id,
                departmentSkillCount.get(department.Id) + 1
              );
            });
          });

          this.showSkillSelector = Array.from(
            departmentSkillCount.values()
          ).some((count) => count > 1);

          if (res.DepartmentList && res.DepartmentList.length == 1) {
            this.employeeSkillList = this.employeeSkillListTemp.filter(
              (skill) =>
                skill.Departments.some(
                  (department) => department.Id === res.DepartmentList[0].Id
                )
            );
            if (this.employeeSkillList.length === 1) {
              this.skillSelectionId = this.employeeSkillList[0].Id;
            }
          }
        } else if (res.SkillList && res.SkillList.length == 1) {
          this.skillSelectionId = res.SkillList[0].Id;
          this.showSkillSelector = false;
        }

        this.updateSliderDisabled();
      }
    );

    /** INAKTIVITÄT: Timer initialisieren */
    this.initInactivityTimer();
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.stopTimer();

    // INAKTIVITÄT: Aufräumen
    if (this.inactivityTimer) {
      clearTimeout(this.inactivityTimer);
    }
    if (this.globalEventsSub) {
      this.globalEventsSub.unsubscribe();
    }
  }

  handleClockEvent(event) {
    this.timeRecordingService.getEmployeeLastStatus(this.employeeId);
  }

  startTimer() {
    if (this.timer) {
      clearInterval(this.timer);
    }

    if (this.currentTimeStatus === ClockEventType.ClockIn) {
      this.timer = setInterval(() => {
        this.TotalSecondsWorkedToday++;
        this.workedTimeDisplay = this.formatTime(this.TotalSecondsWorkedToday);
      }, 1000);
    }
  }

  stopTimer() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  formatTime(seconds: number): string {
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${hrs.toString().padStart(2, '0')}:${mins
      .toString()
      .padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  }

  onClose(): void {
    this.stopTimer();
    this.dialogRef.close();
  }

  onDepartmentChange(event) {
    this.employeeSkillList = this.employeeSkillListTemp.filter((skill) =>
      skill.Departments.some((department) => department.Id === event.value)
    );
    if (this.employeeSkillList.length === 1) {
      this.skillSelectionId = this.employeeSkillList[0].Id;
    }
    this.skillValue.enable();
    this.departmentSelectionInvalid = false; // Reset des Invalid-Flags
  }

  onBackdropClick(): void {
    this.dialogRef.close();
  }

  startDrag(event: MouseEvent | TouchEvent) {
    if (this.sliderDisabled) {
      if (this.showDepartmentSelector && this.departmentSelectionId === 0) {
        this.departmentSelectionInvalid = true;
      } else {
        this.departmentSelectionInvalid = false;
        if (this.departmentSelectionId === 0) {
          this.toastr.warning(
            this.translate.instant(
              'YOU ARE CURRENTLY NOT ASSIGNED TO ANY DEPARTMENT'
            )
          );
        }
      }
      return;
    }

    event.preventDefault();
    const handle = this.handle.nativeElement;
    const slider = this.slider.nativeElement;
    const sliderTrackGreen = this.sliderTrackGreen.nativeElement;
    const sliderTrackRed = this.sliderTrackRed.nativeElement;

    this.renderer.addClass(handle, 'dragging'); // Remove transition effect during drag

    const onMouseMove = (e: MouseEvent | TouchEvent) => {
      let clientX: number;
      if (e instanceof MouseEvent) {
        clientX = e.clientX;
      } else {
        clientX = e.touches[0].clientX;
      }

      const sliderRect = slider.getBoundingClientRect();
      const newLeft = Math.min(
        Math.max(0, clientX - sliderRect.left),
        slider.offsetWidth - handle.offsetWidth
      );
      handle.style.left = `${newLeft}px`;

      // Update track widths
      sliderTrackGreen.style.width = `${newLeft}px`;
      sliderTrackRed.style.width = `${
        slider.offsetWidth - newLeft - handle.offsetWidth
      }px`;

      if (newLeft >= slider.offsetWidth - handle.offsetWidth) {
        this.startTime();
        stopDrag();
      }
    };

    const stopDrag = () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('touchmove', onMouseMove);
      document.removeEventListener('mouseup', stopDrag);
      document.removeEventListener('touchend', stopDrag);

      // Setze den Slider sanft zurück zur Startposition
      this.renderer.removeClass(handle, 'dragging'); // Re-enable transition effect
      this.renderer.setStyle(handle, 'left', '0px'); // Reset handle position
      this.renderer.setStyle(sliderTrackGreen, 'width', '0%'); // Reset green track width
      this.renderer.setStyle(sliderTrackRed, 'width', '0%'); // Reset red track width
    };

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('touchmove', onMouseMove);
    document.addEventListener('mouseup', stopDrag);
    document.addEventListener('touchend', stopDrag);
  }

  startTime() {
    console.log('Time started!');

    const currentTimeUtc = new Date(new Date().toISOString()); // Convert to UTC

    if (this.currentTimeStatus === ClockEventType.ClockIn) {
      this.stopTimer();
    }

    // Call the service to start or stop the time
    const newEventType =
      this.currentTimeStatus === ClockEventType.ClockIn
        ? ClockEventType.ClockOut
        : ClockEventType.ClockIn;

    this.timeRecordingService
      .addClockEvent({
        EventType: newEventType,
        EventTime: currentTimeUtc,
        DepartmentId: this.departmentSelectionId,
        SkillId: this.skillSelectionId,
        EmployeeId: this.data?.employeeId ?? 0,
        CreatedByType: this.data?.employeeId
          ? CreatedByType.EmployeeTerminal
          : CreatedByType.Employee,
      })
      .then(() => {
        this.currentTimeStatus = newEventType;
        this.timeIconBadgeColor =
          this.currentTimeStatus === ClockEventType.ClockOut
            ? this.redBackground
            : this.greenBackground;

        this.timeIconColor =
          this.currentTimeStatus === ClockEventType.ClockOut
            ? '#7d0726ff'
            : '#0e522ad7'; // Update the icon color

        // Update the slider handle and text color
        const handle = this.handle.nativeElement;
        const sliderTextStart = this.sliderTextStart.nativeElement;
        const sliderTextStop = this.sliderTextStop.nativeElement;

        if (this.currentTimeStatus === ClockEventType.ClockIn) {
          this.renderer.removeClass(handle, 'clock-out');
          this.renderer.addClass(handle, 'clock-in');
          this.renderer.addClass(sliderTextStart, 'clock-in');
          this.renderer.removeClass(sliderTextStop, 'clock-out');
          this.startTimer();
        } else {
          this.renderer.removeClass(handle, 'clock-in');
          this.renderer.addClass(handle, 'clock-out');
          this.renderer.removeClass(sliderTextStart, 'clock-in');
          this.renderer.addClass(sliderTextStop, 'clock-out');
        }
        this.handleClockEvent(null);
      });
  }

  updateSliderDisabled() {
    this.sliderDisabled =
      this.departmentSelectionId === 0 || this.skillSelectionId === 0;
  }

  updateIconColors() {
    this.timeIconBadgeColor =
      this.currentTimeStatus === ClockEventType.Nothing ||
      this.currentTimeStatus === ClockEventType.ClockOut
        ? this.redBackground
        : this.greenBackground;

    this.timeIconColor =
      this.currentTimeStatus === ClockEventType.Nothing ||
      this.currentTimeStatus === ClockEventType.ClockOut
        ? '#7d0726ff'
        : '#0e522ad7';
  }

  updateLastEventTimeString() {
    const userLanguage = navigator.language;
    this.LastEventTimeString = this.LastEventTime.toLocaleString(userLanguage, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });
  }

  updateFormControls() {
    if (this.currentTimeStatus === ClockEventType.ClockIn) {
      this.skillValue.disable();
      this.departmentValue.disable();
    } else {
      this.skillValue.enable();
      this.departmentValue.enable();
    }
  }

  /** INAKTIVITÄT: Zusätzliche Methode zum Starten des Timers */
  private initInactivityTimer(): void {
    // Lies den Timeout z.B. aus TerminalSettings => TimeDialogInactivityTimeout
    // Falls nicht definiert => fallback 30 Sek.
    const inactivitySecs =
      this.companySettingsService.CompanySettings.TerminalSettings
        ?.PinPadInactivityTimeout ?? 30;

    // Timer direkt starten
    this.resetInactivityTimer(inactivitySecs);

    // Globale Events => Timer-Reset
    const mousemove$ = fromEvent(document, 'mousemove');
    const click$ = fromEvent(document, 'click');
    const keydown$ = fromEvent(document, 'keydown');
    const touchstart$ = fromEvent(document, 'touchstart');

    this.globalEventsSub = merge(mousemove$, click$, keydown$, touchstart$)
      .pipe(debounceTime(300))
      .subscribe(() => {
        this.resetInactivityTimer(inactivitySecs);
      });
  }

  private resetInactivityTimer(inactivitySecs: number): void {
    if (this.inactivityTimer) {
      clearTimeout(this.inactivityTimer);
    }
    this.inactivityTimer = setTimeout(() => {
      this.dialogRef.close();
    }, inactivitySecs * 1000);
  }
}
