/********************************************************************
 * time-recording.service.ts
 * Version mit Offline-Unterstützung & Zusammenführen von Offline-/Online-Einträgen
 ********************************************************************/
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
dayjs.extend(duration);

import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, interval, Subscription } from 'rxjs';
import {
  AbsenceType,
  ApiEndPoints,
  ApiMethod,
  BalancePeriod,
  ClockEventType,
  CreatedByType,
  EventType,
} from '../../../core/services/const';
import { HttpService } from '../../../core/services/http/http.service';

// Models / Interfaces
import { AddClockEvent } from '../model/add-clock-event.model';
import { UpdateClockEvent } from '../model/update-clock-event.model';
import { ClockEventOneDayDto } from '../model/clock-event-dto.model';
import { DayWeekMonth } from '../model/day-week-month';
import { EmployeeHourBalanceDto } from '../model/employee-hour-balance-dto';
import {
  ClockEventDto,
  EmployeeLastStatusDto,
} from '../model/employee-last-status-dto.model';
import { EmployeeService } from '../../employee/services/employee.service';
import { CompanySettings } from '../../settings/model/company-settings.model';
import { CompanySettingsService } from '../../settings/services/company-settings.service';
import { AuthService } from '../../auth/services/auth/auth.service';

// Beispiel-Interface für lokale/offline Einträge
export interface OfflineClockEvent {
  id: number; // Temporäre (Offline) ID, z. B. negative Zahl oder Timestamp
  eventTime: Date; // Zeitpunkt (lokal)
  eventType: ClockEventType; // ClockIn, ClockOut, usw.
  departmentId?: number;
  skillId?: number;
  isSynced: boolean; // Schon zum Server hochgeladen?
  createdAt: Date; // Wann offline erstellt
}

@Injectable({
  providedIn: 'root',
})
export class TimeRecordingService {
  // --------------------------------------------------------------
  // Globale/öffentliche Variablen zum Zeitbereich
  // --------------------------------------------------------------
  public timeRange = {
    StarTime: dayjs().startOf('month').toDate(),
    EndTime: dayjs().toDate(),
  };

  public currentPeriod: BalancePeriod = BalancePeriod.Month;

  // Offline-Storage-Key (z. B. localStorage)
  private offlineEventsKey = 'offlineClockEvents';

  private updateSubscription: Subscription;

  // --------------------------------------------------------------
  // Subjects/Observables für Komponenten
  // --------------------------------------------------------------
  private clockEvenSource = new BehaviorSubject<ClockEventOneDayDto>(null);
  public clockEvent = this.clockEvenSource.asObservable();

  private clockEventListSource = new BehaviorSubject<any[]>([]);
  public clockEventList = this.clockEventListSource.asObservable();

  private clockEventForDayListSource = new BehaviorSubject<any[]>([]);
  public clockEventForDay = this.clockEventForDayListSource.asObservable();

  private employeeStatusListSource = new BehaviorSubject<any[]>([]);
  public employeeStatusList = this.employeeStatusListSource.asObservable();

  private employeeStatusSource = new BehaviorSubject<EmployeeLastStatusDto>(
    null
  );
  public employeeStatus = this.employeeStatusSource.asObservable();

  private timeRecordingTimelineSource = new BehaviorSubject<any>(null);
  public timeRecordingTimeline =
    this.timeRecordingTimelineSource.asObservable();

  private dayWeekMonthStatsQuerySource = new BehaviorSubject<DayWeekMonth>(
    null
  );
  public dayWeekMonthStatsQuery =
    this.dayWeekMonthStatsQuerySource.asObservable();

  private employeeTimeRecoringEventListSource = new BehaviorSubject<any>(null);
  public employeeTimelineEventList =
    this.employeeTimeRecoringEventListSource.asObservable();

  private workedTimeDisplaySource = new BehaviorSubject<boolean>(false);
  public workedTimeDisplay = this.workedTimeDisplaySource.asObservable();

  private ComplianceMessageSource = new BehaviorSubject<boolean>(false);
  public ComplianceMessage = this.ComplianceMessageSource.asObservable();

  private totalSecondsWorkedToday: number = 0;

  private employeeLiveStatusSource = new BehaviorSubject<any[]>([]);
  public employeeLiveStatus$ = this.employeeLiveStatusSource.asObservable();

  private employeeHourBalanceSource =
    new BehaviorSubject<EmployeeHourBalanceDto>(null);
  public employeeHourBalance$ = this.employeeHourBalanceSource.asObservable();

  // Hilfsvariablen
  public oneDay: any;
  public currentWeekStart: any;

  // --------------------------------------------------------------
  // Konstruktor & Konstruktor-Logik
  // --------------------------------------------------------------
  constructor(
    private httpClient: HttpService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private employeeService: EmployeeService,
    private companySetting: CompanySettingsService,
    private authService: AuthService
  ) {
    // Browser-/PWA-Event "online", um Offline-Daten zu synchronisieren
    window.addEventListener('online', () => {
      this.syncOfflineEvents();
    });
  }

  // --------------------------------------------------------------
  // 1) ClockEvent hinzufügen (online/offline)
  // --------------------------------------------------------------
  public addClockEvent(event: AddClockEvent): Promise<void> {
    if (
      !navigator.onLine &&
      this.companySetting.CompanySettings.TimeTrackingSettings
        .OfflineTimeTracking
    ) {
      if (
        this.companySetting.CompanySettings.TimeTrackingSettings
          .OfflineTimeTracking
      ) {
        // Offline => localStorage (oder IndexedDB) speichern
        return this.storeOfflineEvent(event);
      } else {
        // Offline-Modus nicht aktiviert
        this.toastr.error(
          this.translate.instant('YOU ARE CURRENTLY OFFLINE'),
          this.translate.instant('ERROR'),
          { timeOut: 10000 }
        );
        return Promise.reject('Offline mode not enabled');
      }
    } else {
      event.CreatedByType = CreatedByType.Employee;
      if (this.authService.isManegeOrTeamLeader) {
        event.CreatedByType = CreatedByType.Admin;
      }
      if (this.authService.isTerminal) {
        event.CreatedByType = CreatedByType.EmployeeTerminal;
      }

      // Online => direkt ans Backend
      return this.addClockEventOnline(event);
    }
  }

  /**
   * Speichert Event direkt im Backend (nur wenn online).
   */
  private addClockEventOnline(event: AddClockEvent): Promise<void> {
    const endPoint = event.EmployeeId
      ? ApiEndPoints.AddClockEventAdmin
      : ApiEndPoints.AddClockEvent;

    return new Promise<void>((resolve, reject) => {
      this.httpClient.requestCall(endPoint, ApiMethod.POST, event).subscribe(
        (res: number) => {
          if (res > 0) {
            this.handleAddClockEventSuccess(event);
            this.getEmployeeLastStatus(event.EmployeeId);
            resolve();
          }
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  /**
   * Speichert das Event offline.
   */
  private storeOfflineEvent(event: AddClockEvent): Promise<void> {
    // Markiere das Event, damit wir wissen, dass es offline erstellt wurde
    event.CreatedByType = CreatedByType.EmployeeOffline; // <-- ggf. erstelle "EmployeeOffline" in deinem Enum

    // Baue ein Offline-Objekt
    const offlineEvent: OfflineClockEvent = {
      id: this.generateTempId(), // negative ID o. Ä.
      eventTime: event.EventTime,
      eventType: event.EventType,
      departmentId: event.DepartmentId,
      skillId: event.SkillId,
      createdAt: new Date(),
      isSynced: false,
    };

    let offlineList: OfflineClockEvent[] = JSON.parse(
      localStorage.getItem(this.offlineEventsKey) || '[]'
    );
    offlineList.push(offlineEvent);
    localStorage.setItem(this.offlineEventsKey, JSON.stringify(offlineList));

    // Visuelles Feedback
    this.toastr.info(
      this.translate.instant('CLOCK_EVENT_OFFLINE_STORED'),
      this.translate.instant('OFFLINE_MODE')
    );
    return Promise.resolve();
  }

  /**
   * Standard-Methode bei erfolgreichem Hinzufügen (Online).
   */
  private handleAddClockEventSuccess(event: AddClockEvent): void {
    let messageKey = '';
    let titleKey = 'Success';
    let iconHtml = '';

    switch (event.EventType) {
      case ClockEventType.ClockIn:
        messageKey = 'ClockInSuccessMessage';
        iconHtml = '<i class="fa fa-play fa-lg fa-beat"></i> ';
        break;
      case ClockEventType.ClockOut:
        messageKey = 'ClockOutSuccessMessage';
        iconHtml = '<i class="fa fa-stop fa-lg fa-beat"></i> ';
        break;
      case ClockEventType.BreakStart:
        messageKey = 'BreakStartSuccessMessage';
        iconHtml = '<i class="fa fa-coffee"></i> ';
        break;
      case ClockEventType.BreakEnd:
        messageKey = 'BreakEndSuccessMessage';
        iconHtml = '<i class="fa fa-coffee"></i> ';
        break;
      default:
        messageKey = 'ClockEventAddedSuccessMessage';
        iconHtml = '';
        break;
    }

    this.toastr.success(
      iconHtml + '&nbsp;' + this.translate.instant(messageKey),
      this.translate.instant(titleKey),
      { enableHtml: true }
    );
    // Beispiel: Refresh der Mehrtage-Liste
    this.getClockEventsForMultipleDays(
      this.timeRange.StarTime,
      this.timeRange.EndTime
    );
  }

  /**
   * Generiert eine temporäre Offline-ID, z. B. negative Zufallszahl.
   */
  private generateTempId(): number {
    return -Math.floor(Math.random() * 1000000000);
  }

  // --------------------------------------------------------------
  // 2) Synchronisation Offline -> Online
  // --------------------------------------------------------------
  public async syncOfflineEvents(): Promise<void> {
    // 1) Prüfen, ob wir überhaupt online sind
    if (!navigator.onLine) return;

    // 2) Offline-Liste aus localStorage holen
    let offlineList: OfflineClockEvent[] = JSON.parse(
      localStorage.getItem(this.offlineEventsKey) || '[]'
    );
    if (!offlineList.length) return;

    // 3) Temporärer Array für „nicht erfolgreiche“ Syncs
    const stillPending: OfflineClockEvent[] = [];

    // 4) Für jedes offlineEvent im localStorage => Sync
    for (const offlineEvent of offlineList) {
      // a) Baue ein AddClockEvent
      const addClockEvent: AddClockEvent = {
        EventTime: offlineEvent.eventTime,
        EventType: offlineEvent.eventType,
        DepartmentId: offlineEvent.departmentId,
        SkillId: offlineEvent.skillId,
        CreatedByType: CreatedByType.EmployeeOffline,
      };

      try {
        // b) Versuche, es online zu senden
        await this.addClockEventOnline(addClockEvent);
        // Erfolg => kein Push in stillPending
      } catch (err) {
        // c) Fehler => in stillPending schieben
        console.error('Fehler beim Sync:', err);
        stillPending.push(offlineEvent);
      }
    }

    // 5) Aktualisierte Liste in localStorage schreiben
    localStorage.setItem(this.offlineEventsKey, JSON.stringify(stillPending));

    // 6) Optional: Wenn alles leer => Erfolgsmeldung
    if (!stillPending.length) {
      this.toastr.info(
        this.translate.instant('SYNC_COMPLETED'),
        this.translate.instant('OFFLINE_MODE')
      );
    }
  }

  /**************************************************************
   * Ausschnitt aus time-recording.service.ts
   **************************************************************/
  public getEmployeeLastStatus(employeeId = 0): void {
    if (navigator.onLine) {
      // ONLINE
      this.httpClient
        .requestCall(
          ApiEndPoints.GetEmployeeLastStatus + '/' + employeeId,
          ApiMethod.GET
        )
        .subscribe(
          (status: EmployeeLastStatusDto) => {
            // Jetzt den Status normal weiterverarbeiten:
            localStorage.setItem(
              'backendClockEventsKey',
              JSON.stringify(status)
            );
            // ========== NEU: Events paaren, bevor wir sie im Status ablegen ==========
            if (status?.ClockEventOneDayDto?.ClockEvents?.length) {
              const unlinked = status.ClockEventOneDayDto.ClockEvents;
              const { pairs, earliest, latest, totalSecondsWorkedToday } =
                this.buildPairedEvents(unlinked);
              status.ClockEventOneDayDto.ClockEvents = pairs;
              status.ClockEventOneDayDto.FristClockIn = earliest;
              status.ClockEventOneDayDto.LastClockOut = latest;
              status.ClockEventOneDayDto.TotalWorkTimeDay = Math.round(
                totalSecondsWorkedToday / 60
              ); // in Minuten;
              status.TotalSecondsWorkedToday = totalSecondsWorkedToday;
            }

            // ========== Ende NEU ==========

            this.employeeStatusSource.next(status);
            this.totalSecondsWorkedToday = status.TotalSecondsWorkedToday;

            if (status.LastEventType === ClockEventType.ClockIn) {
              this.workedTimeDisplaySource.next(true);
            } else {
              this.workedTimeDisplaySource.next(false);
            }

            if (status.ComplianceMessage?.length > 0) {
              this.ComplianceMessageSource.next(true);
            } else {
              this.ComplianceMessageSource.next(false);
            }
          },
          (error) => {
            console.error('Fehler beim Laden aus dem Backend:', error);
          }
        );
    } else {
      // OFFLINE-Zustand

      // 1) Vorher gecachten Server-Stand laden oder Fallback
      let cachedStatus: EmployeeLastStatusDto;
      const cachedString = localStorage.getItem('backendClockEventsKey');
      if (cachedString) {
        cachedStatus = JSON.parse(cachedString);
      } else {
        // Falls noch nie online
        cachedStatus = {
          EmployeeStatus: 'Unknown',
          LastEventType: ClockEventType.Nothing,
          LastEventTime: new Date(),
          TotalSecondsWorkedToday: 0,
          DepartmentId: 0,
          SkillId: 0,
          ComplianceMessage: [],
          ClockEventOneDayDto: {
            TotalWorkTimeDay: 0,
            TotalBreakTimeDay: 0,
            FristClockIn: null,
            LastClockOut: null,
            EventDate: null,
            ClockEvents: [],
            TotalOvertime: 0,
          },
        };
      }

      // 2) Offline-Events laden
      const offlineList = this.getOfflineClockEvents();
      const todayStr = new Date().toDateString();
      const offlineToday = offlineList.filter(
        (ev) => new Date(ev.eventTime).toDateString() === todayStr
      );

      // 2a) Hole offline DepartmentList:
      const employeeDetails =
        this.employeeService.getEmployeeDetailsSource.value;
      const departmentList = employeeDetails?.DepartmentList || [];

      // 3) Offline-Events umwandeln in ClockEventDto
      offlineToday.forEach((offEv) => {
        // Department ermitteln:
        const deptId = offEv.departmentId || 0;
        const foundDept = departmentList.find((d) => d.Id === deptId);
        const deptName = foundDept ? foundDept.Name : '(Offline Dept)';

        cachedStatus.ClockEventOneDayDto.ClockEvents.push({
          EventStartId: offEv.id,
          EventEndId: 0,
          EventStart: offEv.eventTime,
          EventEnd: offEv.eventTime,
          EventType: offEv.eventType,
          DepartmentName: deptName,
        });
      });

      // 4) ALLE Events (Backend + Offline) sortieren und neu berechnen
      const combinedEvents = [...cachedStatus.ClockEventOneDayDto.ClockEvents];
      // Sortieren nach EventStart
      combinedEvents.sort((a, b) => {
        const tA = new Date(a.EventStart).getTime();
        const tB = new Date(b.EventStart).getTime();
        return tA - tB;
      });

      // 5) NEU: Events paaren + Gesamtzeit berechnen
      const { pairs, earliest, latest, totalSecondsWorkedToday } =
        this.buildPairedEvents(combinedEvents as ClockEventDto[]);

      // 6) Übernimm die gemappten Werte
      cachedStatus.ClockEventOneDayDto.ClockEvents = pairs;
      cachedStatus.ClockEventOneDayDto.FristClockIn = earliest;
      cachedStatus.ClockEventOneDayDto.LastClockOut = latest;
      // Wir rechnen die „Arbeitszeit in Minuten“:
      cachedStatus.ClockEventOneDayDto.TotalWorkTimeDay = Math.round(
        totalSecondsWorkedToday / 60
      );
      // Und auch als Sekundensumme:
      cachedStatus.TotalSecondsWorkedToday = totalSecondsWorkedToday;

      // 7) Letztes EventType/Time (optional)
      if (combinedEvents.length > 0) {
        const lastPair = combinedEvents[combinedEvents.length - 1];
        cachedStatus.LastEventType = lastPair.EventType;
        cachedStatus.LastEventTime = lastPair.EventEnd;
      }

      // 8) State updaten, workedTimeDisplay usw.:
      this.employeeStatusSource.next(cachedStatus);
      this.totalSecondsWorkedToday = cachedStatus.TotalSecondsWorkedToday;
      if (cachedStatus.LastEventType === ClockEventType.ClockIn) {
        this.workedTimeDisplaySource.next(true);
      } else {
        this.workedTimeDisplaySource.next(false);
      }
      this.ComplianceMessageSource.next(false);
    }
  }

  /**
   * Nimmt eine Liste ungekoppelter ClockEvents (EventStart == EventEnd)
   * und baut daraus Paare (z.B. ClockIn–ClockOut). Zusätzlich wird
   * die gesamte Arbeitszeit für alle Paare summiert.
   */
  private buildPairedEvents(unlinked: ClockEventDto[]): {
    pairs: ClockEventDto[];
    earliest: Date | null;
    latest: Date | null;
    totalSecondsWorkedToday: number; // in Minuten
  } {
    if (!unlinked || unlinked.length === 0) {
      return {
        pairs: [],
        earliest: null,
        latest: null,
        totalSecondsWorkedToday: 0,
      };
    }

    // 1) Sortieren nach EventStart-Zeit (aufsteigend)
    unlinked.sort((a, b) => {
      const timeA = new Date(a.EventStart).getTime();
      const timeB = new Date(b.EventStart).getTime();
      return timeA - timeB;
    });

    const pairs: ClockEventDto[] = [];
    let pending: ClockEventDto | null = null;

    // 2) Durchlaufen und Paare bilden
    for (const current of unlinked) {
      // String -> Date, falls nötig
      const curStart =
        typeof current.EventStart === 'string'
          ? new Date(current.EventStart)
          : current.EventStart;
      const curEnd =
        typeof current.EventEnd === 'string'
          ? new Date(current.EventEnd)
          : current.EventEnd;

      if (!pending) {
        // Kein offenes "Start-Event" vorhanden
        pending = {
          EventStartId: current.EventStartId,
          EventEndId: 0, // Wird erst beim "Schließen" gesetzt
          EventStart: curStart,
          EventEnd: curEnd,
          EventType: current.EventType,
          DepartmentName: current.DepartmentName,
        };
      } else {
        // Es gibt schon ein offenes "pending" => wir bilden ein Paar
        pairs.push({
          EventStartId: pending.EventStartId,
          EventEndId: current.EventStartId,
          EventStart: pending.EventStart,
          EventEnd: curStart, // oder curEnd, da unlinked = Start == End
          EventType: pending.EventType,
          DepartmentName: pending.DepartmentName,
        });
        // pending ist "verbraucht"
        pending = null;
      }
    }

    // 3) Falls noch etwas "offen" ist => bis "jetzt" verlängern
    if (pending) {
      const now = new Date();
      pairs.push({
        EventStartId: pending.EventStartId,
        EventEndId: 0,
        EventStart: pending.EventStart,
        EventEnd: now,
        EventType: pending.EventType,
        DepartmentName: pending.DepartmentName,
      });
    }

    // 4) earliest & latest ermitteln
    if (pairs.length === 0) {
      return {
        pairs: [],
        earliest: null,
        latest: null,
        totalSecondsWorkedToday: 0,
      };
    }

    let earliest = pairs[0].EventStart;
    let latest = pairs[0].EventEnd;
    for (const p of pairs) {
      if (p.EventStart < earliest) earliest = p.EventStart;
      if (p.EventEnd > latest) latest = p.EventEnd;
    }

    // 5) Gesamt-Arbeitszeit berechnen (in Minuten).
    //    Nur für Paare, die "ClockIn" als EventType haben.
    //    Falls dein Backend "ClockIn" = 1 bedeutet,
    //    prüfe ggf. stattdessen p.EventType === 1.
    let totalSeconds = 0;
    for (const p of pairs) {
      if (p.EventType === ClockEventType.ClockIn) {
        const diffMs = p.EventEnd.getTime() - p.EventStart.getTime();
        if (diffMs > 0) {
          totalSeconds += Math.floor(diffMs / 1000);
        }
      }
    }
    const totalSecondsWorkedToday = totalSeconds;

    return {
      pairs,
      earliest,
      latest,
      totalSecondsWorkedToday,
    };
  }

  // Offline-spezifische Hilfsmethode
  private getOfflineClockEvents(): OfflineClockEvent[] {
    return JSON.parse(localStorage.getItem(this.offlineEventsKey) || '[]');
  }

  private calculateOfflineWorkedSeconds(
    offlineEvents: OfflineClockEvent[]
  ): number {
    let totalSeconds = 0;
    const sortedEvents = [...offlineEvents].sort((a, b) => {
      return new Date(a.eventTime).getTime() - new Date(b.eventTime).getTime();
    });
    let pendingClockInTime: Date | null = null;

    sortedEvents.forEach((ev) => {
      const currentTime = new Date(ev.eventTime);
      if (
        ev.eventType === ClockEventType.ClockIn ||
        ev.eventType === ClockEventType.BreakEnd
      ) {
        if (!pendingClockInTime) {
          pendingClockInTime = currentTime;
        }
      } else if (
        ev.eventType === ClockEventType.ClockOut ||
        ev.eventType === ClockEventType.BreakStart
      ) {
        if (pendingClockInTime) {
          const diffSec =
            (currentTime.getTime() - pendingClockInTime.getTime()) / 1000;
          totalSeconds += diffSec;
          pendingClockInTime = null;
        }
      }
    });

    // Falls noch eingestempelt
    if (pendingClockInTime) {
      const now = new Date();
      const diffSec = (now.getTime() - pendingClockInTime.getTime()) / 1000;
      totalSeconds += diffSec;
    }

    return Math.floor(totalSeconds);
  }

  // --------------------------------------------------------------
  // 4) Übrige API-Methoden (unverändert)
  // --------------------------------------------------------------
  public updateClockEvent(event: UpdateClockEvent): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.httpClient
        .requestCall(ApiEndPoints.UpdateClockEvent, ApiMethod.PUT, event)
        .subscribe(
          (res: number) => {
            if (res > 0) {
              this.toastr.success(
                this.translate.instant('Clock event updated successfully'),
                this.translate.instant('Success')
              );
              resolve();
            }
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  public deleteClockEvent(id: number, isAdmin: boolean): Promise<void> {
    const endPoint = isAdmin
      ? ApiEndPoints.DeleteClockEventAdmin
      : ApiEndPoints.DeleteClockEvent;
    return new Promise<void>((resolve, reject) => {
      this.httpClient
        .requestCall(`${endPoint}/${id}`, ApiMethod.DELETE)
        .subscribe(
          (res: boolean) => {
            if (res) {
              this.toastr.success(
                this.translate.instant('Clock event deleted successfully'),
                this.translate.instant('Success')
              );
              resolve();
            } else {
              reject(new Error('Failed to delete clock event.'));
            }
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  public getAllClockEvents(): void {
    this.httpClient
      .requestCall(ApiEndPoints.GetAllClockEvents, ApiMethod.GET)
      .subscribe((list: any[]) => {
        this.clockEvenSource.next(null);
        this.clockEvenSource.next(list as any);
      });
  }

  public getEmployeeHourBalance(
    period?: BalancePeriod,
    year?: number,
    month?: number,
    week?: number
  ): void {
    if (period == null || period == undefined) {
      period = this.currentPeriod;
    } else {
      this.currentPeriod = period;
    }

    let queryParams = `/${period}`;
    if (year) queryParams += `&year=${year}`;
    if (month) queryParams += `&month=${month}`;
    if (week) queryParams += `&week=${week}`;

    this.httpClient
      .requestCall(
        `${ApiEndPoints.GetEmployeeHourBalance}${queryParams}`,
        ApiMethod.GET
      )
      .subscribe((data: EmployeeHourBalanceDto) => {
        this.employeeHourBalanceSource.next(data);
      });
  }

  public getEmployeeStatus(): void {
    this.httpClient
      .requestCall(ApiEndPoints.GetEmployeeStatus, ApiMethod.GET)
      .subscribe((list: any[]) => {
        this.employeeStatusListSource.next(list);
      });
  }

  public getDayWeekMonthStatsQuery(employeeId = 0): void {
    this.httpClient
      .requestCall(
        ApiEndPoints.GetDayWeekMonthStatsQuery + '/' + employeeId,
        ApiMethod.GET
      )
      .subscribe((dayWeekMonth: DayWeekMonth) => {
        this.dayWeekMonthStatsQuerySource.next(dayWeekMonth);
      });
  }

  public getTimeRecordingTimelineForCompany(
    currentWeekStart = null,
    oneDay = false,
    searchKeywords = '*'
  ): void {
    searchKeywords = searchKeywords ? searchKeywords : '*';

    if (oneDay == null) {
      oneDay = this.oneDay;
    } else {
      this.oneDay = oneDay;
    }

    if (currentWeekStart == null) {
      currentWeekStart = this.currentWeekStart;
    } else {
      this.currentWeekStart = currentWeekStart;
    }

    const startStr = dayjs(currentWeekStart)
      .utcOffset(0, true)
      .format('YYYY-MM-DD');
    const endPoint = `${ApiEndPoints.GetTimeRecordingTimelineForCompany}/${startStr}/${oneDay}/${searchKeywords}`;

    this.httpClient
      .requestCall(endPoint, ApiMethod.GET)
      .subscribe((result: any) => {
        result.CalenderEvents = result.CalenderEvents.map((entry: any) => {
          entry.start = new Date(entry.start);
          entry.end = new Date(entry.end);
          this.setColorAndClassForEvent(entry);
          return entry;
        });

        this.employeeTimeRecoringEventListSource.next({
          calenderEvents: result.CalenderEvents,
          calenderResources: result.CalenderResources,
          calendarWeekStart: null,
        } as any);
      });
  }

  public getAllClockEventsForDay(
    date: string,
    employeeId: number,
    departmentId: number
  ): void {
    const dateStart = dayjs(date).utcOffset(0, true).format('YYYY-MM-DD');
    const url = `${ApiEndPoints.GetAllClockEventsForDay}/${dateStart}/${employeeId}/${departmentId}`;

    this.httpClient
      .requestCall(url, ApiMethod.GET)
      .subscribe((list: ClockEventOneDayDto) => {
        if (list) {
          list.ClockEvents = list.ClockEvents.filter(
            (ev) =>
              ev.EventType === ClockEventType.ClockIn ||
              ev.EventType === ClockEventType.BreakStart
          );
        }
        this.clockEvenSource.next(list);
      });
  }

  public getClockEventsForMultipleDays(startDate: Date, endDate: Date) {
    const dateStart = dayjs(startDate).format('YYYY-MM-DD');
    const endDateFormatted = dayjs(endDate).format('YYYY-MM-DD');
    const url = `${ApiEndPoints.GetClockEventsForMultipleDays}/${dateStart}/${endDateFormatted}`;

    return this.httpClient
      .requestCall(url, ApiMethod.GET)
      .subscribe((list: ClockEventOneDayDto[]) => {
        list.forEach((event) => {
          event.ClockEvents = event.ClockEvents.filter(
            (ev) =>
              ev.EventType === ClockEventType.ClockIn ||
              ev.EventType === ClockEventType.BreakStart
          );
        });
        this.clockEventListSource.next(list);
      });
  }

  public getLiveStatus(): void {
    this.httpClient
      .requestCall(ApiEndPoints.GetLiveStatus, ApiMethod.GET)
      .subscribe((liveStatusList: any[]) => {
        this.employeeLiveStatusSource.next(liveStatusList);
      });
  }

  // -----------------------------------------------
  // Start/Stop AutoRefresh
  // -----------------------------------------------
  public startAutoRefresh(intervalTime: number = 60000): void {
    this.updateSubscription = interval(intervalTime).subscribe(() => {
      this.getLiveStatus();
    });
  }

  public stopAutoRefresh(): void {
    if (this.updateSubscription) {
      this.updateSubscription.unsubscribe();
    }
  }

  public resetClockEvent(): void {
    this.clockEvenSource.next(null);
  }

  /**
   * Setzt Farbe & CSS-Klasse für bestimmte Event-Typen (Urlaub, TimeRecording,...)
   */
  public setColorAndClassForEvent(event: any): void {
    if (event.eventType == EventType.TimeRecording) {
      event.className = 'vertical-line-time-recordings event-animation-scale';
      event.color = '#c1b4f84d';
      event.textColor = '#000000';
    } else if (event.eventType == EventType.Holiday) {
      event.className = 'vertical-line-holiday event-animation-scale';
      event.color = '#b2ebf249';
      event.textColor = '#000000';
    } else if (event.eventType == EventType.Absence) {
      if (event.absenceType == AbsenceType.Vacation) {
        event.className = 'vertical-line-vacation event-animation-scale';
        event.color = '#d373064d';
        event.textColor = '#000000';
      } else if (event.absenceType == AbsenceType.Sick) {
        event.className = 'vertical-line-sick event-animation-scale';
        event.color = '#9f2b004d';
        event.textColor = '#000000';
      }
    }
  }
}
