<nav
  class="navbar navbar-expand fixed-top navbar-default"
  style="box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2)"
>
  <button class="navbar-toggler" type="button">
    <mat-icon>menu</mat-icon>
  </button>
  <a class="navbar-brand">
    <img
      src="../../../../../assets/img/logo-no-name.png"
      alt=""
      width="40"
      height="40"
    />
    <img
      src="../../../../../assets/img/Logo.PNG"
      alt=""
      width="110"
      height="25"
    />
  </a>
  <div
    [ngbCollapse]="isMenuCollapsed"
    class="collapse navbar-collapse"
    id="navbarSupportedContent"
  >
    <ul
      *ngIf="!isRegisteredStepper"
      class="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll"
    ></ul>
    <ul class="nav navbar-nav">
      <li class="nav-item dropdown">
        <a
          class="nav-link dropdown-toggle"
          href="#"
          id="navbarDropdownMenuLink"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <i class="bi bi-translate"></i> {{ lang }}
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
          <a
            class="dropdown-item"
            (click)="changeLocale('en')"
            href="javascript:void(0)"
            >English</a
          >
          <a
            class="dropdown-item"
            (click)="changeLocale('de')"
            href="javascript:void(0)"
            >Deutsch</a
          >
        </div>
      </li>
    </ul>
  </div>
  <div class="d-none d-sm-none d-md-none d-lg-block d-xl-block ml-auto"></div>
  <li style="list-style-type: none" class="nav-item dropdown">
    <a
      style="width: 140px; text-align: right"
      class="nav-link dropdown-toggle"
      href="#"
      id="navbarDropdown"
      role="button"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      <i class="fa-light fa-circle-user fa-xl"></i> {{ authService.firstName }}
    </a>
    <ul
      class="dropdown-menu col-xs-12"
      aria-labelledby="navbarDropdownMenuLink"
      style="width: 300px; white-space: nowrap"
    >
      <li>
        <a
          class="dropdown-item w-100"
          (click)="onLogoutClick()"
          href="javascript:void(0)"
          style="padding-left: 5px"
        >
          <i class="bi bi-box-arrow-right"></i> {{ "Logout" | translate }}
        </a>
      </li>
    </ul>
  </li>
  <div *ngIf="loading" class="progress-container">
    <mat-progress-bar
      class="audio-progress-bar"
      mode="indeterminate"
    ></mat-progress-bar>
  </div>
</nav>
