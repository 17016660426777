<div class="container content-center">
  <div class="row">
    <div class="col-sm-8 col-md-6 col-lg-4 mx-auto">
      <div class="card shadow-lg">
        <div class="card-body">
          <img
            class="center"
            style="padding-top: 30px"
            src="../../../../../assets/img/Logo.PNG"
            alt="FixPlaner"
            width="152"
            height="80"
          />

          <form
            #form="ngForm"
            (submit)="onSubmit(form)"
            style="margin-top: 20px"
          >
            <div class="form-group mb-3">
              <label
                for="InputEmailOrUserName"
                style="color: var(--font-color)"
              >
                <ng-container *ngIf="!isTerminalMode">
                  {{ "EMAIL" | translate }}
                </ng-container>
                <ng-container *ngIf="isTerminalMode">
                  {{ "TERMINAL_USERNAME" | translate }}
                </ng-container>
              </label>
              <ion-input
                appAutofill
                [type]="isTerminalMode ? 'text' : 'email'"
                class="form-control"
                id="InputEmailOrUserName"
                [placeholder]="
                  isTerminalMode ? 'e.g. terminal01' : 'name@example.com'
                "
                #EmailOrUserName="ngModel"
                name="EmailOrUserName"
                [(ngModel)]="formModel.EmailOrUserName"
              ></ion-input>

              <!-- NEU: Warnhinweis, wenn `.local`-Endung erkannt und nicht isTerminalMode -->
              <div
                *ngIf="showLocalEmailHint"
                class="alert alert-info mt-1"
                style="font-size: small"
              >
                {{ "LOCAL_EMAIL_HINT" | translate }}
              </div>
            </div>

            <div class="form-group mb-3">
              <label for="floatingPassword" style="color: var(--font-color)">
                {{ "PASSWORD" | translate }}
              </label>
              <ion-input
                appAutofill
                type="password"
                class="form-control"
                id="floatingPassword"
                placeholder="Password"
                #Password="ngModel"
                name="Password"
                [(ngModel)]="formModel.Password"
              ></ion-input>
            </div>

            <div class="mb-3 row" style="font-size: small">
              <div class="d-grid">
                <button
                  class="btn btn-primary btn-lg btn-login bi bi-box-arrow-in-right fa-lg"
                  type="submit"
                >
                  {{ "LOG IN" | translate }}
                </button>
              </div>
            </div>

            <div class="text-center">
              <a
                style="
                  color: var(--font-color);
                  font-size: small;
                  text-align: center;
                "
                [routerLink]="['/forgotpassword']"
              >
                {{ "FORGOT PASSWORD" | translate }}
              </a>
              <a
                style="
                  color: var(--font-color);
                  font-size: small;
                  text-align: center;
                  padding-left: 30px;
                "
                [routerLink]="['/registration']"
              >
                {{ "REGISTER" | translate }}
              </a>
            </div>

            <!-- FEHLERANZEIGE bei Fehler -->
            <div
              *ngIf="errorStatus.showError"
              class="alert alert-danger"
              role="alert"
            >
              {{ errorStatus.errorMessage }}
            </div>
          </form>

          <!-- Umschalten auf Terminal vs Employee (hier unten) -->
          <div class="text-center" style="margin-top: 15px">
            <a
              style="cursor: pointer; font-size: small"
              (click)="toggleLoginMode()"
            >
              <ng-container *ngIf="isTerminalMode">
                {{ "SWITCH_TO_EMPLOYEE_LOGIN" | translate }}
              </ng-container>
              <ng-container *ngIf="!isTerminalMode">
                {{ "SWITCH_TO_TERMINAL_LOGIN" | translate }}
              </ng-container>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
