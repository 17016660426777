import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NgForm, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { TranslateService } from '@ngx-translate/core';
import * as dayjs from 'dayjs';
import { ReplaySubject, Subject, Subscription } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/modules/auth/services/auth/auth.service';
import { EmployeeListDto } from '../../../admin/model/department-dto.model';
import { EmployeeService } from '../../../employee/services/employee.service';
import { Absence } from '../../model/absence.model';
import { AbsenceService } from '../../services/absence.service';

@Component({
  selector: 'app-add-absence',
  templateUrl: './add-absence.component.html',
  styleUrls: ['./add-absence.component.css'],
})
export class AddAbsenceComponent implements OnInit {
  range = new UntypedFormGroup({
    start: new UntypedFormControl(),
    end: new UntypedFormControl(),
  });

  public filteredCategory: ReplaySubject<EmployeeListDto[]> = new ReplaySubject<
    EmployeeListDto[]
  >(1);
  public categoryFilterCtrl: UntypedFormControl = new UntypedFormControl();
  employeeCategory: EmployeeListDto[];
  protected _onDestroy = new Subject<void>();
  @ViewChild('EmployeeId', { static: true }) categorySelect: MatSelect;
  private _subscriptionEmployeeCategory: Subscription;

  absenceTypeList = [
    { id: 1, label: this.translate.instant('Vacation') },
    { id: 2, label: this.translate.instant('Sickness') },
  ];
  // minDate = new Date();

  IncomeTypeId = '';
  formModel: Absence = {
    Id: 0,
    EventId: null,
    StarTime: null,
    EndTime: null,
    RequestStatus: 0,
    AbsenceType: 0,
    EmployeeId: 0,
    Comment: null,
    AllDay: true,
  };

  minTime = dayjs().startOf('day').set('h', 8).format('HH:mm');

  hour = {
    StartTime: '',
    EndTime: '',
  };

  minEndTime = dayjs().startOf('day').set('h', 8).format('HH:mm');
  workingDays: number = null;

  constructor(
    public service: AbsenceService,
    public employeeService: EmployeeService,
    public dialogRef: MatDialogRef<AddAbsenceComponent>,
    public authService: AuthService,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  toggleAllDay() {
    // this.formModel.AllDay = !this.formModel.AllDay;
    // Add any logic here if needed
  }
  ngOnInit(): void {
    if (this.data && this.data.startTime && this.data.endTime) {
      // decrease one minute from end time to avoid end time is in the next day than start time and convert to date
      this.formModel.StarTime = dayjs(this.data.startTime).toDate();
      this.formModel.EndTime = dayjs(this.data.endTime)
        .subtract(1, 's')
        .toDate();
      this.onDateRangeChange();
    }

    if (this.authService.isManegeOrTeamLeader) {
      this._subscriptionEmployeeCategory =
        this.employeeService.employeeNameList.subscribe((list) => {
          this.employeeCategory = list as EmployeeListDto[];
          this.filteredCategory.next(this.employeeCategory.slice());
        });
      this.employeeService.getEmployeeNameList();
      this.categoryFilterCtrl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterCategory();
        });
    }
  }

  onSubmit(form: NgForm): void {
    if (!form.value) {
      return;
    }

    if (!!!form.value.AllDay) {
      form.value.StarTime = dayjs(form.value.StarTime).startOf('day').toDate(); // "7.1.2025 00:00:00" (lokal)
      form.value.EndTime = dayjs(form.value.EndTime).endOf('day').toDate(); // "8.1.2025 23:59:59" (lokal)
      form.value.AllDay = true;
    } else {
      // Partial Day -> combineDateTime (Stunden+Minuten)
      form.value.StarTime = this.combineDateTime(
        form.value.StarTime,
        this.hour.StartTime
      );
      form.value.EndTime = this.combineDateTime(
        form.value.EndTime,
        this.hour.EndTime
      );
      // => Hier kann man optional noch in .toDate() konvertieren
    }

    // => Dann an Service übergeben
    this.service.submitAbsence(form.value);
    this.onClose();
  }

  onDateRangeChange(): void {
    if (this.formModel.StarTime && this.formModel.EndTime) {
      this.service
        .getWorkingDays(
          new Date(this.formModel.StarTime),
          new Date(this.formModel.EndTime)
        )
        .subscribe(
          (days: number) => {
            this.workingDays = days;
          },
          (error) => {
            console.error('Fehler beim Abrufen der Arbeitstage', error);
          }
        );
    } else {
      this.workingDays = null;
    }
  }

  combineDateTime(date: string, time: string): string {
    // Assuming time is in 'HH:mm' format
    const formattedDate = dayjs(date).format('YYYY-MM-DD');
    return dayjs(`${formattedDate} ${time}`).format('YYYY-MM-DD HH:mm');
  }

  onClose(): void {
    this.dialogRef.close();
  }

  onTimeset(event): void {
    const eventTime = event.split(':');
    this.minEndTime = dayjs()
      .startOf('day')
      .set('h', eventTime[0])
      .set('m', eventTime[1])
      .add(5, 'minutes')
      .format('HH:mm');
  }

  ///////////////////// Filter Employee Section ///////////////////
  protected filterCategory() {
    if (!this.employeeCategory) {
      return;
    }
    // get the search keyword
    let search = this.categoryFilterCtrl.value;
    if (!search) {
      this.filteredCategory.next(this.employeeCategory.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the Category
    this.filteredCategory.next(
      this.employeeCategory.filter(
        (category) => category.Name.toLowerCase().indexOf(search) > -1
      )
    );
  }

  updateIncomeType(ctrl): void {
    if (ctrl.selectedIndex === 0) {
      this.IncomeTypeId = '';
    } else {
      this.IncomeTypeId = this.employeeCategory[ctrl.selectedIndex - 1]
        .Id as '';
    }
  }
  ngAfterViewInit() {
    this.setInitialValue();
  }

  protected setInitialValue() {
    this.filteredCategory
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        if (this.categorySelect) {
          this.categorySelect.compareWith = (
            a: EmployeeListDto,
            b: EmployeeListDto
          ) => a && b && a === b;
        }
      });
  }
  ///////////////////// End Filter Income Section ///////////////////
}
