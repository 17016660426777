<div class="table-container">
  <mat-table
    [dataSource]="listData"
    matSort
    (matSortChange)="sortEmployeeData($event)"
  >
    <ng-container matColumnDef="PersonalNumber">
      <mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 16px"
        >{{ "PERSONALNUMBER" | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element">{{
        element.PersonalNumber
      }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="FirstName">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        style="font-size: 16px; color: var(--font-color)"
        >{{ "First Name" | translate }}
      </mat-header-cell>
      <mat-cell
        *matCellDef="let element"
        style="color: var(--primary-color-blue); font-weight: bold"
        >{{ element.FirstName }}</mat-cell
      >
    </ng-container>
    <ng-container matColumnDef="LastName">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        style="font-size: 16px; color: var(--font-color)"
        >{{ "Last Name" | translate }}
      </mat-header-cell>
      <mat-cell
        *matCellDef="let element"
        style="color: var(--primary-color-blue); font-weight: bold"
        >{{ element.LastName }}</mat-cell
      >
    </ng-container>
    <ng-container matColumnDef="Department">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        style="font-size: 16px; color: var(--font-color)"
        >{{ "Department" | translate }}</mat-header-cell
      >
      <mat-cell *matCellDef="let element">{{ element.Department }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="Skill">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        style="font-size: 16px; color: var(--font-color)"
      >
        {{ "ABILITY/COMPETENCE" | translate }}</mat-header-cell
      >
      <mat-cell *matCellDef="let element">
        <div
          *ngFor="let icon of element.SkillIcons"
          class="badge badge-pill badge-primary"
          style="
            font-size: small;
            background-color: var(--primary-color-blue);
            margin: 2px;
          "
        >
          <i class="{{ icon }}" style="font-size: larger"></i>
        </div>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="JobType">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        style="font-size: 16px; color: var(--font-color)"
      >
        {{ "EMPLOYMENT" | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ getJobTypeLabel(element.JobType) }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="MobileNo">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        style="font-size: 16px; color: var(--font-color)"
        >{{ "Mobile Number" | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.MobileNo }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="WorkingHours">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        style="font-size: 16px; color: var(--font-color)"
        >{{ "WORKING HOURS" | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element"
        >{{ element.WorkingHours }} Std</mat-cell
      >
    </ng-container>
    <ng-container matColumnDef="Email">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        style="font-size: 16px; color: var(--font-color)"
        >{{ "Email" | translate }}</mat-header-cell
      >
      <mat-cell *matCellDef="let element">
        <div
          *ngIf="element.Email != null"
          class="badge badge-pill badge-primary"
          [ngStyle]="{
            color: 'var(--font-color)',
            'font-size': 'small',
            'background-color':
              element.EmailStatus === 2
                ? 'var(--email-status-color-accepted)'
                : 'var(--email-status-color-not-accepted)',
            margin: '2px'
          }"
        >
          {{ element.Email }} {{ element.EmailStatus === 2 ? "✓" : "" }}
        </div>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="HomePhoneNo">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        style="font-size: 16px; color: var(--font-color)"
        >{{ "Home Phone" | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.HomePhoneNo }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="Street1">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        style="font-size: 16px; color: var(--font-color)"
        >{{ "Street" | translate }}</mat-header-cell
      >
      <mat-cell *matCellDef="let element">{{ element.Street1 }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="Actions">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell
        style="display: flex; justify-content: flex-end"
        *matCellDef="let element"
      >
        <button
          mat-icon-button
          [matMenuTriggerFor]="menu"
          aria-label="Example icon-button with a menu"
        >
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="onEmployeeUpdate(element.Id)">
            <i class="fa-solid fa-user-pen"></i>
            <span> {{ "EDIT" | translate }}</span>
          </button>
          <button
            *ngIf="element.Role !== 1"
            mat-menu-item
            (click)="onEmailUpdate(element.Id)"
          >
            <i class="fa-light fa-envelope"></i>
            <span> {{ "INVITE EMPLOYEE" | translate }}</span>
          </button>
          <button
            mat-menu-item
            (click)="
              onResetEmployeePin(
                element.Id,
                element.FirstName,
                element.LastName
              )
            "
          >
            <i class="fa-solid fa-key"></i>
            <span> {{ "RESET PIN" | translate }} </span>
          </button>
          <button
            mat-menu-item
            *ngIf="element.Role !== 1"
            (click)="
              onActivateDeactivateEmployee(
                element.Id,
                element.IsActive,
                element.FirstName,
                element.LastName
              )
            "
          >
            <i *ngIf="element.IsActive" class="fa-solid fa-user-slash"></i>
            <i *ngIf="!element.IsActive" class="fa-solid fa-user-check"></i>
            <span *ngIf="!element.IsActive"> {{ "ACTIVATE" | translate }}</span>
            <span *ngIf="element.IsActive">
              {{ "DEACTIVATE" | translate }}</span
            >
          </button>
          <button
            *ngIf="element.Role !== 1"
            mat-menu-item
            (click)="
              onEmployeeDelete(element.Id, element.FirstName, element.LastName)
            "
          >
            <i class="fa-solid fa-user-xmark"></i>
            <span> {{ "DELETE" | translate }}</span>
          </button>
        </mat-menu>
      </mat-cell>
    </ng-container>
    <mat-header-row
      *matHeaderRowDef="displayedColumns; sticky: true"
      style="font-weight: 500; background-color: white; font: bold"
    ></mat-header-row>
    <mat-row
      *matRowDef="let row; columns: displayedColumns"
      [ngClass]="{
        positive: row.Value > 0,
        negative: row.Value < 0,
        'inactive-row': !row.IsActive
      }"
    ></mat-row>
  </mat-table>
  <mat-paginator
    [length]="paginationService.totalCount"
    [pageSize]="paginationService.pageCount"
    [pageSizeOptions]="[5, 10, 15, 25, 100]"
    (page)="onPageSwitch($event)"
    class="mat-paginator-sticky"
  >
  </mat-paginator>
</div>
