import {
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { OnboardingService } from 'src/app/core/component/onboarding/onboarding.service';
import { OnboardingStep } from 'src/app/core/component/onboarding/onboarding-step.model';
import { TranslateService } from '@ngx-translate/core';
import { CompanySettingsService } from 'src/app/modules/settings/services/company-settings.service';
import {
  AddTerminalDto,
  TerminalService,
} from '../../services/terminal.service';

@Component({
  selector: 'app-add-terminal',
  templateUrl: './add-terminal.component.html',
  styleUrls: ['./add-terminal.component.css'],
})
export class AddTerminalComponent implements OnInit, OnDestroy {
  /** Reactive Form-Gruppe */
  terminalForm: FormGroup;

  @Input() showOnboarding: boolean = false;

  isOnboardingActive = false;

  onboardingSteps: OnboardingStep[] = [
    {
      anchorId: 'terminalname-field',
      title: this.translate.instant('TERMINAL_NAME_FIELD_TITLE'),
      content: this.translate.instant('EXPLANATION_FOR_TERMINAL_NAME_FIELD'),
      placement: 'left',
    },
    {
      anchorId: 'terminalpassword-field',
      title: this.translate.instant('TERMINAL_PASSWORD_FIELD_TITLE'),
      content: this.translate.instant(
        'EXPLANATION_FOR_TERMINAL_PASSWORD_FIELD'
      ),
      placement: 'left',
    },
  ];

  /** Bereinigter Unternehmensname (klein, ohne Sonderzeichen/Leerzeichen) */
  companyNameSlug = '';

  constructor(
    private service: TerminalService,
    private onboardingService: OnboardingService,
    private translate: TranslateService,
    private companySettingsService: CompanySettingsService,
    @Inject(MAT_DIALOG_DATA) @Optional() public data: any,
    @Optional() public dialogRef: MatDialogRef<AddTerminalComponent>
  ) {}

  ngOnInit(): void {
    this.initCompanyNameSlug();

    this.terminalForm = new FormGroup({
      prefix: new FormControl('', [Validators.required]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        this.validatePasswordStrength.bind(this),
      ]),
      userName: new FormControl(''), // Wird automatisch befüllt
    });

    // Automatisches Zusammensetzen von userName, sobald prefix geändert wird
    this.terminalForm
      .get('prefix')
      .valueChanges.subscribe((prefixValue: string) => {
        const cleanedPrefix = prefixValue?.toLowerCase().replace(/\s+/g, '');
        const finalUserName =
          cleanedPrefix + '@' + this.companyNameSlug + '.local';
        this.terminalForm.patchValue(
          { userName: finalUserName },
          { emitEvent: false }
        );
      });
  }
  private validatePasswordStrength(
    control: FormControl
  ): { [key: string]: boolean } | null {
    const value = control.value;
    if (!value) return null;

    const hasUpperCase = /[A-Z]/.test(value);
    const hasLowerCase = /[a-z]/.test(value);
    const hasNumeric = /[0-9]/.test(value);
    // ACHTUNG: Der Bindestrich '-' muss mit '\\-' maskiert sein
    const hasSpecial = /[!@#$%^&*(),.?":{}|<>\\-]/.test(value);

    const valid = hasUpperCase && hasLowerCase && hasNumeric && hasSpecial;
    return valid ? null : { passwordStrength: true };
  }

  /** Unternehmensname in Slug umwandeln */
  private initCompanyNameSlug(): void {
    const rawName =
      this.companySettingsService.CompanySettings.CompanyName || '';
    this.companyNameSlug = rawName
      .toLowerCase()
      .replace(/\s+/g, '') // Leerzeichen entfernen
      .replace(/[^\w-]+/g, ''); // Sonderzeichen entfernen
  }

  toggleOnboarding(): void {
    this.isOnboardingActive = !this.isOnboardingActive;
    if (this.isOnboardingActive) {
      this.onboardingService.setSteps(this.onboardingSteps);
    } else {
      this.onboardingService.removeSteps(this.onboardingSteps);
    }
  }

  /** Beim Abschicken das Terminal anlegen */
  onSubmit(): void {
    if (this.terminalForm.valid) {
      const prefixValue = this.terminalForm.get('prefix').value;
      const passwordValue = this.terminalForm.get('password').value;

      const payload: AddTerminalDto = {
        UserName: prefixValue,
        Password: passwordValue,
      };

      this.service.addTerminal(payload).subscribe({
        next: (res: any) => {
          if (res) {
            this.service.getAllTerminals();
            this.dialogRef?.close(true);
          }
        },
        error: (err) => {
          console.error('Fehler beim Anlegen des Terminals', err);
        },
      });
    }
  }

  onClose(): void {
    this.dialogRef?.close(false);
  }

  ngOnDestroy(): void {
    this.onboardingService.removeSteps(this.onboardingSteps);
  }
}
