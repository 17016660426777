import { CalendarSettings } from './calendar-settings.model';
import { ShiftSettings } from './shift-swap-settings.model';
import { TerminalSettings } from './terminal-settings.model';
import { TimeTrackingSettings } from './time-tracking-settings.model';
import { WorkingHoursAct } from './working-hours-act.model';

export class CompanySettings {
  CompanyName: string;
  TimeZone: string = 'Europe/Berlin';
  CalendarOptions: CalendarSettings;
  ShiftSettings: ShiftSettings;
  TimeTrackingSettings: TimeTrackingSettings;
  WorkStructure: number = WorkStructure.Departments;
  IsoCountryCode: string;
  IsoRegionCode: string;
  TerminalSettings: TerminalSettings;

  constructor() {
    this.CalendarOptions = new CalendarSettings();
    this.ShiftSettings = new ShiftSettings();
    this.TimeTrackingSettings = new TimeTrackingSettings();
    this.TerminalSettings = new TerminalSettings();
  }

  static fromStorage(data: CompanySettings): CompanySettings {
    const companySettings = new CompanySettings();

    if (data && data.CompanyName) {
      companySettings.CompanyName = data.CompanyName;
      companySettings.TimeZone = data.TimeZone;
      companySettings.IsoCountryCode = data.IsoCountryCode;
      companySettings.IsoRegionCode = data.IsoRegionCode;
      companySettings.WorkStructure =
        data.WorkStructure || WorkStructure.Departments;

      // CalendarOptions ...
      if (data.CalendarOptions) {
        companySettings.CalendarOptions = {
          FirstDay: data.CalendarOptions.FirstDay || 1,
          WeekendDays: data.CalendarOptions.WeekendDays || [6, 0],
          BusinessHoursStartTime:
            data.CalendarOptions.BusinessHoursStartTime || '08:00',
          BusinessHoursEndTime:
            data.CalendarOptions.BusinessHoursEndTime || '23:00',
        };
      }

      // TerminalSettings ...
      if (!data.TerminalSettings) {
        // Falls im DB-Objekt noch kein TerminalSettings existiert
        companySettings.TerminalSettings = new TerminalSettings();
      } else {
        // Objekt befüllen
        companySettings.TerminalSettings = {
          PinPadInactivityTimeout:
            data.TerminalSettings.PinPadInactivityTimeout ?? 15,
          SearchResetInactivityTimeout:
            data.TerminalSettings.SearchResetInactivityTimeout ?? 15,
          AllowPin: data.TerminalSettings.AllowPin ?? true,
          AllowNfc: data.TerminalSettings.AllowNfc ?? false,
          AllowQr: data.TerminalSettings.AllowQr ?? false,
          PinLength: data.TerminalSettings.PinLength ?? 4,
          ShowEmployeeList: data.TerminalSettings.ShowEmployeeList ?? true,
          ShowEmployeeStatus: data.TerminalSettings.ShowEmployeeStatus ?? true,
        };
      }

      // ShiftSettings ...
      if (data.ShiftSettings) {
        companySettings.ShiftSettings = {
          DaysInAdvance: data.ShiftSettings.DaysInAdvance || 5,
          AllowShiftSwap: data.ShiftSettings.AllowShiftSwap || false,
          ShiftPlanerActive: data.ShiftSettings.ShiftPlanerActive || false,
        };
      }

      // TimeTrackingSettings ...
      if (data.TimeTrackingSettings) {
        companySettings.TimeTrackingSettings = {
          TimeTrackingActive:
            data.TimeTrackingSettings.TimeTrackingActive || false,
          GPSTracking: data.TimeTrackingSettings.GPSTracking || false,
          AutoStopTimeOnMaxHours:
            data.TimeTrackingSettings.AutoStopTimeOnMaxHours || false,
          OfflineTimeTracking:
            data.TimeTrackingSettings.OfflineTimeTracking || false,
          MaxDailyWorkingHours:
            data.TimeTrackingSettings.MaxDailyWorkingHours || 10,
          MaxWeeklyWorkingHours:
            data.TimeTrackingSettings.MaxWeeklyWorkingHours || 48,
          MinDailyWorkingHours:
            data.TimeTrackingSettings.MinDailyWorkingHours || 8,
          MinWeeklyWorkingHours:
            data.TimeTrackingSettings.MinWeeklyWorkingHours || 40,
          MaxConsecutiveWorkDays:
            data.TimeTrackingSettings.MaxConsecutiveWorkDays || 5,
          BreakTime: data.TimeTrackingSettings.BreakTime || 30,
          LongBreakTime: data.TimeTrackingSettings.LongBreakTime || 45,
          AfterHours: data.TimeTrackingSettings.AfterHours || 2,
          RestPeriods: {
            DailyRestPeriod:
              data.TimeTrackingSettings.RestPeriods.DailyRestPeriod || 11,
            WeeklyRestPeriod:
              data.TimeTrackingSettings.RestPeriods.WeeklyRestPeriod || 24,
            HolidayRestPeriod:
              data.TimeTrackingSettings.RestPeriods.HolidayRestPeriod || 24,
          },
          Flextime: {
            IsFlextime: data.TimeTrackingSettings.Flextime.IsFlextime || false,
            CoreTimeStart:
              data.TimeTrackingSettings.Flextime.CoreTimeStart || '09:00',
            CoreTimeEnd:
              data.TimeTrackingSettings.Flextime.CoreTimeEnd || '15:00',
          },
          Overtime: {
            IsOvertime:
              data.TimeTrackingSettings?.Overtime?.IsOvertime || false,
            MaxOvertimeHours:
              data.TimeTrackingSettings?.Overtime?.MaxOvertimeHours || 10,
          },
          Notifications: {
            Enable: data.TimeTrackingSettings?.Notifications?.Enable || false,
            Email: data.TimeTrackingSettings?.Notifications?.Email || false,
          },
          EmployeeSettings: {
            CanAddTimeRecord:
              data.TimeTrackingSettings?.EmployeeSettings?.CanAddTimeRecord ||
              false,
            CanEditTimeRecord:
              data.TimeTrackingSettings?.EmployeeSettings?.CanEditTimeRecord ||
              false,
            CanDeleteTimeRecord:
              data.TimeTrackingSettings?.EmployeeSettings
                ?.CanDeleteTimeRecord || false,
          },
          AllowMobileAppClockIn:
            data.TimeTrackingSettings.AllowMobileAppClockIn ?? false,
          EnableTerminal: data.TimeTrackingSettings.EnableTerminal ?? true,
        };
      }
    }

    return companySettings;
  }
}

export enum WorkStructure {
  Departments = 1,
  Projects = 2,
  Both = 3,
}
