import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/core/services/http/http.service';
import { ApiEndPoints, ApiMethod } from 'src/app/core/services/const';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
export interface AddTerminalDto {
  UserName: string;
  Password: string;
}

export interface TerminalDto {
  UserName: string;
  Email: string;
  // ggf. weitere Felder
}

/*
   B) TerminalEmployeeDTOs - Basierend auf dem
   was du vom Server bekommst bei GetTerminalEmployeeList
*/
export interface TerminalEmployeeDto {
  EmployeeId: number;
  FirstName: string;
  LastName: string;
  Status: string; // "ClockedIn", "ClockedOut", "OnBreak", "NoRecord", ...
}

export interface PaginationDto {
  PageSize: number;
  PageIndex: number;
  AllItemsLength: number;
}

export interface TerminalEmployeePreviewDto {
  DataList: TerminalEmployeeDto[];
  Pagination: PaginationDto;
}

@Injectable({
  providedIn: 'root',
})
export class TerminalService {
  private terminalsListSource = new BehaviorSubject<TerminalDto[]>([]);
  terminalsList$ = this.terminalsListSource.asObservable();

  public employeesListSource = new BehaviorSubject<TerminalEmployeeDto[]>([]);
  employeesList$ = this.employeesListSource.asObservable();

  totalCount = 0;

  constructor(
    private httpClient: HttpService,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {}

  // =====================[ TERMINAL CRUD ]=====================
  getAllTerminals(): void {
    this.httpClient
      .requestCall(ApiEndPoints.GetTerminals, ApiMethod.GET)
      .subscribe((res) => {
        const terminalList = res as TerminalDto[];
        this.terminalsListSource.next(terminalList);
      });
  }

  addTerminal(payload: AddTerminalDto): Observable<any> {
    return this.httpClient
      .requestCall(ApiEndPoints.AddTerminal, ApiMethod.POST, payload)
      .pipe(
        tap((res: any) => {
          if (res?.Success) {
            this.toastr.success(
              this.translate.instant('TERMINAL_ADDED_SUCCESSFULLY'),
              this.translate.instant('SUCCESS')
            );
          }
        })
      );
  }

  deleteTerminal(userName: string): Observable<any> {
    return this.httpClient
      .requestCall(ApiEndPoints.DeleteTerminal, ApiMethod.POST, { userName })
      .pipe(
        tap((res: any) => {
          if (res?.Success) {
            this.toastr.success(
              this.translate.instant('TERMINAL_DELETED_SUCCESSFULLY'),
              this.translate.instant('SUCCESS')
            );
          }
        })
      );
  }

  // =====================[ EMPLOYEE LIST for Terminal ]=====================
  getTerminalEmployeeList(
    searchTerm: string,
    includeStatus: boolean,
    pageIndex: number,
    pageSize: number
  ): Observable<TerminalEmployeePreviewDto> {
    const payload = {
      SearchTerm: searchTerm,
      IncludeStatus: includeStatus,
      PageIndex: pageIndex,
      PageSize: pageSize,
    };

    return this.httpClient.requestCall(
      ApiEndPoints.TerminalEmployeeList,
      ApiMethod.POST,
      payload
    );
  }

  // =====================[ Validate PIN + Create ClockEvent ]=====================

  /**
   * Ruft das Backend auf, um den PIN zu validieren und einen ClockEvent anzulegen.
   * @param employeeId ID des Mitarbeiters
   * @param pinCode Eingegebener PIN
   * @param eventType z.B. "ClockIn" oder "ClockOut"
   * @returns Observable<boolean> => true, wenn erfolgreich, sonst Fehler
   */
  validatePinAndClockEvent(
    employeeId: number,
    pinCode: string
  ): Observable<boolean> {
    const payload = {
      EmployeeId: employeeId,
      PinCode: pinCode,
    };

    return this.httpClient.requestCall(
      ApiEndPoints.ValidatePinAndClockEvent,
      ApiMethod.POST,
      payload
    );
  }
}
