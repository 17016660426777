<div class="modal-dialog modal-lg custom-modal" role="document">
  <div class="modal-content">
    <div class="modal-header text-center">
      <h5 class="modal-title w-100">
        {{ "ADD_NEW_TERMINAL" | translate }}
      </h5>
    </div>

    <div class="modal-body">
      <form
        [formGroup]="terminalForm"
        autocomplete="off"
        (ngSubmit)="onSubmit()"
      >
        <!-- Terminal-Name (Prefix) -->
        <div class="form-group mb-4">
          <label for="prefix" class="required-label">
            {{ "TERMINAL_NAME" | translate }}
          </label>
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              id="terminalname-field"
              formControlName="prefix"
              autocomplete="off"
            />
            <div class="input-group-append">
              <span class="input-group-text">@{{ companyNameSlug }}.local</span>
            </div>
          </div>
          <div
            *ngIf="
              terminalForm.get('prefix').touched &&
              terminalForm.get('prefix').hasError('required')
            "
            class="text-danger"
          >
            <em>{{ "TERMINAL NAME IS REQUIRED" | translate }}</em>
          </div>
        </div>

        <div class="form-group mb-4">
          <label for="password" class="required-label">
            {{ "TERMINAL_PASSWORD" | translate }}
          </label>
          <input
            type="password"
            class="form-control"
            id="terminalpassword-field"
            formControlName="password"
            autocomplete="new-password"
          />

          <div
            class="error-container text-danger"
            *ngIf="terminalForm.get('password').touched"
          >
            <em *ngIf="terminalForm.get('password').hasError('minlength')">
              {{ "PASSWORD MUST BE AT LEAST 8 CHARACTERS LONG" | translate }}
            </em>
            <em
              *ngIf="terminalForm.get('password').hasError('passwordStrength')"
            >
              {{
                "PASSWORD MUST CONTAIN UPPERCASE LOWERCASE NUMBER AND SPECIAL CHARACTER"
                  | translate
              }}
            </em>
          </div>
        </div>

        <input type="hidden" formControlName="userName" />
      </form>
    </div>

    <div class="modal-footer d-flex justify-content-between">
      <button
        class="btn btn-outline-secondary"
        (click)="onClose()"
        type="button"
        data-dismiss="modal"
      >
        {{ "CLOSE" | translate }}
      </button>
      <!-- Der Submit-Button ruft onSubmit() auf, validiert das FormGroup -->
      <button
        class="btn btn-primary active"
        [disabled]="terminalForm.invalid"
        (click)="onSubmit()"
      >
        {{ "SAVE" | translate }}
      </button>
    </div>
  </div>
</div>
