import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { ConfirmationDialogInputComponent } from 'src/app/core/component/confirmation-dialog-input/confirmation-dialog-input.component';
import { ConfirmationDialogComponent } from 'src/app/core/component/confirmation-dialog/confirmation-dialog.component';
import { ReportsService } from '../../services/reports.service';
import { ReportPaginationService } from './report-pagination.service';
import { FileType } from 'src/app/core/services/const';

@Component({
  selector: 'app-report-list',
  templateUrl: './report-list.component.html',
  styleUrls: ['./report-list.component.css'],
})
export class ReportListComponent implements OnInit, OnDestroy {
  @Input() startDate: string;
  @Input() endDate: string;
  @Input() employeeId: number;
  @Input() departmentIds: number[];

  private subscriptionEmployeeList: Subscription;
  listData: MatTableDataSource<any>;

  displayedColumns: string[] = [
    'EmployeeName',
    'Department',
    'Diagram',
    'TargetWorkTime',
    'EffectiveWorkTime',
    'TotalWorkTime',
    'TotalOvertimeTime',
    'Actions',
  ];

  constructor(
    public paginationService: ReportPaginationService,
    public service: ReportsService
  ) {}

  ngOnInit(): void {
    // Hier abonnieren wir den employeeList-Stream
    this.subscriptionEmployeeList = this.service.employeeList.subscribe(
      (list) => {
        const cloned = list.map((x) => Object.assign({}, x));
        this.listData = new MatTableDataSource(cloned);
      }
    );
  }

  // Hier unsubscriben wir
  ngOnDestroy(): void {
    if (this.subscriptionEmployeeList) {
      this.subscriptionEmployeeList.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.startDate ||
      changes.endDate ||
      changes.employeeId ||
      changes.departmentIds
    ) {
      this.service.getEmployeeWorkHoursReport(
        this.startDate,
        this.endDate,
        this.employeeId,
        this.departmentIds
      );
    }
  }

  onPageSwitch(event: PageEvent): void {
    this.paginationService.change(event);
    this.service.getEmployeeWorkHoursReport(
      this.startDate,
      this.endDate,
      this.employeeId,
      this.departmentIds
    );
  }

  sortEmployeeData(event): void {
    this.paginationService.change(event);
    this.service.getEmployeeWorkHoursReport(
      this.startDate,
      this.endDate,
      this.employeeId,
      this.departmentIds
    );
  }

  getEmployeeWorkHoursReportPdfQuery(
    employeeId: number,
    departmentId: number,
    employeeName: string
  ): void {
    this.service.GetEmployeeWorkHoursReportQuery(
      this.startDate,
      this.endDate,
      employeeId,
      [departmentId],
      FileType.Pdf,
      employeeName
    );
  }

  getEmployeeWorkHoursReportExcelQuery(
    employeeId: number,
    departmentId: number,
    employeeName: string
  ): void {
    this.service.GetEmployeeWorkHoursReportQuery(
      this.startDate,
      this.endDate,
      employeeId,
      [departmentId],
      FileType.Excel,
      employeeName
    );
  }
}
